import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { useNavigate } from 'react-router-dom';
import { useFirebaseData } from '../../hooks/useFirebaseData';
import { FirebaseService } from '../../services/firebase.service';
import { User, Order, Product, Category, Banner } from '../../types/types';

interface SearchResult {
  id: string;
  type: 'user' | 'order' | 'product' | 'category' | 'banner';
  title: string;
  subtitle: string;
  route: string;
}

const SearchBar: React.FC<{ adminId: string }> = ({ adminId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const { data: users = {} } = useFirebaseData<User>(FirebaseService.getUsers);
  const { data: orders = {} } = useFirebaseData<Order>(FirebaseService.getOrders);
  const { data: products = {} } = useFirebaseData<Product>(FirebaseService.getProducts);
  const { data: categories = {} } = useFirebaseData<Category>(FirebaseService.getCategories);
  const { data: banners = {} } = useFirebaseData<Banner>(FirebaseService.getBanners);

  useEffect(() => {
    const searchData = async () => {
      if (!searchTerm) {
        setResults([]);
        return;
      }

      setIsSearching(true);
      const searchTermLower = searchTerm.toLowerCase();
      const searchResults: SearchResult[] = [];

      // Search users
      Object.entries(users)
        .filter(([_, user]) => user.sellerId === adminId)
        .forEach(([id, user]) => {
          if (
            user.name.toLowerCase().includes(searchTermLower) ||
            user.phoneNumber.includes(searchTerm)
          ) {
            searchResults.push({
              id,
              type: 'user',
              title: user.name,
              subtitle: user.phoneNumber,
              route: '/users'
            });
          }
        });

      // Search orders
      Object.entries(orders)
        .filter(([_, order]) => order.sellerId === adminId)
        .forEach(([id, order]) => {
          if (order.shortId.toLowerCase().includes(searchTermLower)) {
            searchResults.push({
              id,
              type: 'order',
              title: `Order #${order.shortId}`,
              subtitle: `₹${order.totalAmount}`,
              route: '/orders'
            });
          }
        });

      // Search products
      Object.entries(products)
        .filter(([_, product]) => product.sellerId === adminId)
        .forEach(([id, product]) => {
          if (product.name.toLowerCase().includes(searchTermLower)) {
            searchResults.push({
              id,
              type: 'product',
              title: product.name,
              subtitle: `₹${product.price}`,
              route: '/products'
            });
          }
        });

      // Search categories
      Object.entries(categories)
        .filter(([_, category]) => category.sellerId === adminId)
        .forEach(([id, category]) => {
          if (category.name.toLowerCase().includes(searchTermLower)) {
            searchResults.push({
              id,
              type: 'category',
              title: category.name,
              subtitle: `${category.subcategories?.length || 0} subcategories`,
              route: '/categories'
            });
          }
        });

      // Search banners
      Object.entries(banners)
        .filter(([_, banner]) => banner.sellerId === adminId)
        .forEach(([id, banner]) => {
          if (banner.title.toLowerCase().includes(searchTermLower)) {
            searchResults.push({
              id,
              type: 'banner',
              title: banner.title,
              subtitle: banner.active ? 'Active' : 'Inactive',
              route: '/banners'
            });
          }
        });

      setResults(searchResults.slice(0, 10));
      setIsSearching(false);
    };

    const timeoutId = setTimeout(searchData, 300);
    return () => clearTimeout(timeoutId);
  }, [searchTerm, users, orders, products, categories, banners, adminId]);

  const getIcon = (type: string) => {
    switch (type) {
      case 'user':
        return <PersonIcon />;
      case 'order':
        return <ShoppingCartIcon />;
      case 'product':
        return <InventoryIcon />;
      case 'category':
        return <CategoryIcon />;
      case 'banner':
        return <ViewCarouselIcon />;
      default:
        return <SearchIcon />;
    }
  };

  const handleResultClick = (result: SearchResult) => {
    navigate(result.route);
    setSearchTerm('');
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: isMobile ? '100%' : 300, mb: isMobile ? 2 : 0 }}>
      <TextField
        fullWidth
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setAnchorEl(e.currentTarget);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
          endAdornment: searchTerm && (
            <InputAdornment position="end">
              {isSearching ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchTerm('');
                    setAnchorEl(null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
        size="small"
      />
      <Popper
        open={Boolean(anchorEl) && searchTerm.length > 0}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ width: anchorEl?.clientWidth, zIndex: 1300 }}
      >
        <Paper elevation={3}>
          <List sx={{ maxHeight: 400, overflow: 'auto' }}>
            {results.length > 0 ? (
              results.map((result) => (
                <ListItem
                  key={`${result.type}-${result.id}`}
                  button
                  onClick={() => handleResultClick(result)}
                >
                  <ListItemIcon>{getIcon(result.type)}</ListItemIcon>
                  <ListItemText
                    primary={result.title}
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {result.subtitle}
                      </Typography>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText
                  primary={
                    searchTerm
                      ? "No results found"
                      : "Start typing to search..."
                  }
                />
              </ListItem>
            )}
          </List>
        </Paper>
      </Popper>
    </Box>
  );
};

export default SearchBar; 