import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Banner } from '../types/types';
import { FirebaseService } from '../services/firebase.service';
import ImageSelector from './common/ImageSelector';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import OptimizedImage from './common/OptimizedImage';

interface BannerFormProps {
  open: boolean;
  onClose: () => void;
  banner?: Banner;
  adminId: string;
}

const BannerForm: React.FC<BannerFormProps> = ({
  open,
  onClose,
  banner,
  adminId,
}) => {
  const [formData, setFormData] = useState<Partial<Banner>>(() => {
    if (banner) {
      // Ensure all required fields are present when editing
      return {
        ...banner,
        id: banner.id,
        title: banner.title || '',
        description: banner.description || '',
        imageUrl: banner.imageUrl || '',
        actionUrl: banner.actionUrl || '',
        activationTime: banner.activationTime || Date.now(),
        deactivationTime: banner.deactivationTime || Date.now() + 7 * 24 * 60 * 60 * 1000,
        active: banner.active ?? false,
        sellerId: banner.sellerId || adminId,
      };
    }
    // Default values for new banner
    return {
      title: '',
      description: '',
      imageUrl: '',
      actionUrl: '',
      activationTime: Date.now(),
      deactivationTime: Date.now() + 7 * 24 * 60 * 60 * 1000, // Default 7 days
      active: false,
      sellerId: adminId,
    };
  });

  // Reset form data when banner changes
  useEffect(() => {
    if (banner) {
      setFormData({
        ...banner,
        id: banner.id,
        title: banner.title || '',
        description: banner.description || '',
        imageUrl: banner.imageUrl || '',
        actionUrl: banner.actionUrl || '',
        activationTime: banner.activationTime || Date.now(),
        deactivationTime: banner.deactivationTime || Date.now() + 7 * 24 * 60 * 60 * 1000,
        active: banner.active ?? false,
        sellerId: banner.sellerId || adminId,
      });
    } else {
      // Reset to default values when adding new banner
      setFormData({
        title: '',
        description: '',
        imageUrl: '',
        actionUrl: '',
        activationTime: Date.now(),
        deactivationTime: Date.now() + 7 * 24 * 60 * 60 * 1000,
        active: false,
        sellerId: adminId,
      });
    }
  }, [banner, adminId]);

  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState<string>('');

  const handleImageSelect = async (fileOrUrl: File | string) => {
    setIsLoading(true);
    try {
      let imageUrl: string;
      if (fileOrUrl instanceof File) {
        imageUrl = await FirebaseService.uploadBannerImage(fileOrUrl);
      } else {
        imageUrl = fileOrUrl;
      }
      setFormData(prev => ({ ...prev, imageUrl }));
      setImageError('');
    } catch (error) {
      console.error('Error processing image:', error);
      setImageError('Failed to process image. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!formData.title || !formData.imageUrl) return;

    setIsLoading(true);
    try {
      if (banner?.id) {
        await FirebaseService.updateBanner(banner.id, formData as Banner);
      } else {
        await FirebaseService.addBanner(formData as Banner);
      }
      onClose();
    } catch (error) {
      console.error('Error saving banner:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{banner ? 'Edit Banner' : 'Add New Banner'}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            fullWidth
            label="Title"
            value={formData.title}
            onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
          />
          
          <TextField
            fullWidth
            label="Description"
            value={formData.description}
            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            multiline
            rows={2}
          />
          
          <TextField
            fullWidth
            label="Action URL"
            value={formData.actionUrl}
            onChange={(e) => setFormData(prev => ({ ...prev, actionUrl: e.target.value }))}
          />

          <Box sx={{ my: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>Banner Image</Typography>
            <ImageSelector
              onImageSelect={handleImageSelect}
              folder="banners"
            />
            {formData.imageUrl && (
              <Box mt={2}>
                <OptimizedImage
                  src={formData.imageUrl}
                  alt={formData.title || 'Banner'}
                  width="100%"
                  height={300}
                  loading="eager"
                  style={{
                    borderRadius: '4px'
                  }}
                />
              </Box>
            )}
            {imageError && (
              <Typography color="error" variant="caption" display="block" sx={{ mt: 1 }}>
                {imageError}
              </Typography>
            )}
          </Box>

          <DateTimePicker
            label="Activation Time"
            value={dayjs(formData.activationTime)}
            onChange={(newValue: Dayjs | null) => {
              if (newValue) {
                setFormData(prev => ({ 
                  ...prev, 
                  activationTime: newValue.valueOf() 
                }));
              }
            }}
          />

          <DateTimePicker
            label="Deactivation Time"
            value={dayjs(formData.deactivationTime)}
            onChange={(newValue: Dayjs | null) => {
              if (newValue) {
                setFormData(prev => ({ 
                  ...prev, 
                  deactivationTime: newValue.valueOf() 
                }));
              }
            }}
          />

          <FormControlLabel
            control={
              <Switch
                checked={formData.active}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                  setFormData(prev => ({ ...prev, active: e.target.checked }))
                }
              />
            }
            label="Active"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isLoading || !formData.title || !formData.imageUrl}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BannerForm; 