import React, { useState } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { FirebaseService } from '../../services/firebase.service';
import ImageSelectionDialog from './ImageSelectionDialog';
import { optimizeImage, ImageSizes, getImageDimensions } from '../../utils/imageOptimizer';

interface ImageSelectorProps {
  onImageSelect: (url: string) => void;
  folder?: 'products' | 'categories' | 'banners';
  maxSize?: number;
  currentImage?: string;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({ 
  onImageSelect, 
  folder = 'products',
  maxSize,
  currentImage 
}) => {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [storedImages, setStoredImages] = useState<string[]>([]);
  const [error, setError] = useState<string>('');

  const getMaxSize = () => {
    switch (folder) {
      case 'products':
        return ImageSizes.PRODUCT;
      case 'categories':
        return ImageSizes.CATEGORY;
      case 'banners':
        return ImageSizes.BANNER;
      default:
        return ImageSizes.PRODUCT;
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setLoading(true);
    setError('');

    try {
      // Optimize image without size restrictions
      const optimizedFile = await optimizeImage(file, {
        maxSizeKB: maxSize || getMaxSize(),
        maxWidthOrHeight: folder === 'banners' ? 1920 : 1080,
        isProduct: folder === 'products'
      });

      // Upload optimized image
      const url = await FirebaseService.uploadImage(optimizedFile, folder);
      onImageSelect(url);
    } catch (error) {
      console.error('Error processing image:', error);
      setError('Failed to process image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStorageSelect = async () => {
    setLoading(true);
    setError('');
    try {
      const images = await FirebaseService.listImages(folder);
      setStoredImages(images);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error listing images:', error);
      setError('Failed to load stored images.');
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box display="flex" gap={2} alignItems="center">
        <Button
          variant="contained"
          component="label"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Upload from Device'}
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleFileSelect}
          />
        </Button>
        <Button
          variant="outlined"
          onClick={handleStorageSelect}
          disabled={loading}
        >
          Choose from Storage
        </Button>
      </Box>

      {error && (
        <Typography color="error" variant="caption" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}

      {currentImage && (
        <Box mt={2}>
          <img
            src={currentImage}
            alt="Selected"
            style={{
              maxWidth: '200px',
              maxHeight: '200px',
              objectFit: 'contain',
              borderRadius: '4px'
            }}
          />
        </Box>
      )}

      <ImageSelectionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        images={storedImages}
        onSelect={onImageSelect}
        loading={loading}
      />
    </Box>
  );
};

export default ImageSelector; 