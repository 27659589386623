import { ref, get, set, remove, update, push } from 'firebase/database';
import { db as realtimeDb, storage } from '../config/firebase';
import { Admin, Banner, Category, Order, Product, User } from '../types/types';
import { uploadBytes, getDownloadURL, deleteObject, listAll, ref as storageRef } from 'firebase/storage';
import { optimizeImage, ImageSizes } from '../utils/imageOptimizer';

const getExtension = (filename: string): string => {
  return filename.substring(filename.lastIndexOf('.'));
};

export class FirebaseServiceClass {
  // Admins
  async getAdmins(): Promise<Record<string, Admin>> {
    const snapshot = await get(ref(realtimeDb, 'admins'));
    return snapshot.val() || {};
  }

  async addAdmin(id: string, admin: Admin): Promise<void> {
    return set(ref(realtimeDb, `admins/${id}`), admin);
  }

  async updateAdmin(id: string, updates: Partial<Admin>): Promise<void> {
    return update(ref(realtimeDb, `admins/${id}`), updates);
  }

  async deleteAdmin(id: string): Promise<void> {
    return remove(ref(realtimeDb, `admins/${id}`));
  }

  // Users
  async getUsers(): Promise<Record<string, User>> {
    console.log('Fetching users from Firebase');
    const snapshot = await get(ref(realtimeDb, 'users'));
    const users = snapshot.val() || {};
    console.log('Fetched users:', users);
    return users;
  }

  async getPendingUsers(): Promise<Record<string, User>> {
    const snapshot = await get(ref(realtimeDb, 'pending_users'));
    const users = snapshot.val() || {};
    // Return all users from pending_users, regardless of status
    // This allows us to track approved and denied users as well
    return users;
  }

  async approveUser(userId: string): Promise<void> {
    try {
      // Get the user data from pending_users
      const userRef = ref(realtimeDb, `pending_users/${userId}`);
      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();
      
      if (userData) {
        // Update status in pending_users
        await update(userRef, {
          status: 'approved',
          approvedAt: Date.now()
        });

        // Create a copy in users collection
        await set(ref(realtimeDb, `users/${userId}`), {
          ...userData,
          status: 'approved',
          approvedAt: Date.now()
        });
      }
    } catch (error) {
      console.error('Error approving user:', error);
      throw error;
    }
  }

  async denyUser(userId: string): Promise<void> {
    try {
      const userRef = ref(realtimeDb, `pending_users/${userId}`);
      const userSnapshot = await get(userRef);
      const userData = userSnapshot.val();

      if (userData) {
        // Update the status to denied in pending_users
        await update(userRef, {
          status: 'denied',
          deniedAt: Date.now()
        });
      }
    } catch (error) {
      console.error('Error denying user:', error);
      throw error;
    }
  }

  async deleteUser(userId: string): Promise<void> {
    return remove(ref(realtimeDb, `users/${userId}`));
  }

  // Products
  async getProducts(): Promise<Record<string, Product>> {
    console.log('Fetching products from Firebase');
    const snapshot = await get(ref(realtimeDb, 'products'));
    const products = snapshot.val() || {};
    console.log('Fetched products:', products);
    return products;
  }

  async addProduct(product: Product): Promise<void> {
    return set(ref(realtimeDb, `products/${product.id}`), product);
  }

  async updateProduct(id: string, updates: Partial<Product>): Promise<void> {
    return update(ref(realtimeDb, `products/${id}`), updates);
  }

  async deleteProduct(productId: string): Promise<void> {
    // Get the product first to know its category and subcategory
    const productSnapshot = await get(ref(realtimeDb, `products/${productId}`));
    const product = productSnapshot.val() as Product;
    
    if (product) {
      // Remove from subcategory first
      if (product.categoryId && product.subcategoryId) {
        await this.removeProductFromSubcategory(
          product.categoryId,
          product.subcategoryId,
          productId
        );
      }

      // Then delete the product
      await remove(ref(realtimeDb, `products/${productId}`));
    }
  }

  // Categories
  async getCategories(): Promise<Record<string, Category>> {
    const snapshot = await get(ref(realtimeDb, 'categories'));
    return snapshot.val() || {};
  }

  async addCategory(category: Category): Promise<void> {
    const newCategoryRef = push(ref(realtimeDb, 'categories'));
    return set(newCategoryRef, { 
      ...category, 
      id: newCategoryRef.key,
      sellerId: category.sellerId
    });
  }

  async updateCategory(id: string, category: Category): Promise<void> {
    return update(ref(realtimeDb, `categories/${id}`), category);
  }

  async deleteCategory(id: string): Promise<void> {
    return remove(ref(realtimeDb, `categories/${id}`));
  }

  // Orders
  async getOrders(): Promise<Record<string, Order>> {
    console.log('Fetching orders from Firebase');
    const snapshot = await get(ref(realtimeDb, 'orders'));
    const orders = snapshot.val() || {};
    console.log('Fetched orders:', orders);
    return orders;
  }

  async updateOrderStatus(orderId: string, status: string): Promise<void> {
    try {
      const updates = {
        status,
        lastUpdated: Date.now(),
      };
      
      await update(ref(realtimeDb, `orders/${orderId}`), updates);
      
      await push(ref(realtimeDb, `orders/${orderId}/statusHistory`), {
        status,
        timestamp: Date.now()
      });
    } catch (error) {
      console.error('Error updating order status:', error);
      throw error;
    }
  }

  // Banners
  async getBanners(): Promise<Record<string, Banner>> {
    const snapshot = await get(ref(realtimeDb, 'banners'));
    return snapshot.val() || {};
  }

  async updateBanner(bannerId: string, updates: Partial<Banner>): Promise<void> {
    return update(ref(realtimeDb, `banners/${bannerId}`), {
      ...updates,
      lastUpdated: Date.now()
    });
  }

  async addBanner(banner: Banner): Promise<void> {
    return set(ref(realtimeDb, `banners/${banner.id}`), banner);
  }

  async deleteBanner(bannerId: string): Promise<void> {
    const banner = (await get(ref(realtimeDb, `banners/${bannerId}`))).val();
    if (banner?.imageUrl) {
      const imageRef = storageRef(storage, banner.imageUrl);
      await deleteObject(imageRef);
    }
    return remove(ref(realtimeDb, `banners/${bannerId}`));
  }

  // Image handling methods
  async uploadBannerImage(file: File): Promise<string> {
    try {
      const optimizedFile = await optimizeImage(file, {
        maxSizeKB: ImageSizes.BANNER,
        maxWidthOrHeight: 1920
      });
      
      const sRef = storageRef(storage, `banners/${Date.now()}_${file.name}`);
      // Add metadata for cache control
      const metadata = {
        cacheControl: 'public,max-age=31536000',
        contentType: optimizedFile.type
      };
      
      await uploadBytes(sRef, optimizedFile, metadata);
      return await getDownloadURL(sRef);
    } catch (error) {
      console.error('Error uploading banner image:', error);
      throw error;
    }
  }

  async uploadCategoryImage(file: File): Promise<string> {
    try {
      const optimizedFile = await optimizeImage(file, {
        maxSizeKB: ImageSizes.CATEGORY,
        maxWidthOrHeight: 800
      });
      
      const storageReference = storageRef(storage, `categories/${Date.now()}_${file.name}`);
      const metadata = {
        cacheControl: 'public,max-age=31536000',
        contentType: optimizedFile.type
      };
      
      await uploadBytes(storageReference, optimizedFile, metadata);
      return getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading category image:', error);
      throw error;
    }
  }

  async uploadProductImage(file: File): Promise<string> {
    try {
      const optimizedFile = await optimizeImage(file, {
        maxSizeKB: ImageSizes.PRODUCT,
        maxWidthOrHeight: 600
      });
      
      const storageReference = storageRef(storage, `products/${Date.now()}_${file.name}`);
      const metadata = {
        cacheControl: 'public,max-age=31536000',
        contentType: optimizedFile.type
      };
      
      await uploadBytes(storageReference, optimizedFile, metadata);
      return getDownloadURL(storageReference);
    } catch (error) {
      console.error('Error uploading product image:', error);
      throw error;
    }
  }

  async uploadImage(file: File, folder: string = 'images'): Promise<string> {
    const storagePath = folder === 'products' ? 'photos' : folder;
    const timestamp = Date.now();
    const filename = `${storagePath}_${timestamp}${getExtension(file.name)}`;
    const sRef = storageRef(storage, `${storagePath}/${filename}`);
    
    // Add metadata for cache control
    const metadata = {
      cacheControl: 'public,max-age=31536000', // Cache for 1 year
      contentType: file.type
    };
    
    await uploadBytes(sRef, file, metadata);
    return await getDownloadURL(sRef);
  }

  async listImages(folder: string = 'images'): Promise<string[]> {
    try {
      // Map the folder names to correct storage paths
      const storagePath = folder === 'products' ? 'photos' : folder;
      console.log('Listing images from folder:', storagePath);
      
      const sRef = storageRef(storage, storagePath);
      const result = await listAll(sRef);
      
      const urls = await Promise.all(
        result.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          console.log('Found image:', url);
          return url;
        })
      );
      
      return urls;
    } catch (error) {
      console.error('Error listing images:', error);
      throw error;
    }
  }

  // User details method
  async updateUserDetails(userId: string, updates: { name: string; address: string }): Promise<void> {
    try {
      await update(ref(realtimeDb, `users/${userId}`), {
        name: updates.name,
        address: updates.address,
        lastUpdated: Date.now()
      });
    } catch (error) {
      console.error('Error updating user details:', error);
      throw error;
    }
  }

  async toggleUserBlock(userId: string): Promise<void> {
    const userRef = ref(realtimeDb, `users/${userId}`);
    const snapshot = await get(userRef);
    const userData = snapshot.val();
    return update(userRef, { blocked: !userData.blocked });
  }

  async resetUserPassword(userId: string): Promise<void> {
    return update(ref(realtimeDb, `users/${userId}`), { password: '123456' });
  }

  async updateUserPassword(userId: string, newPassword: string): Promise<void> {
    try {
      await update(ref(realtimeDb, `users/${userId}`), { 
        password: newPassword,
        passwordLastUpdated: Date.now()
      });
    } catch (error) {
      console.error('Error updating password:', error);
      throw error;
    }
  }

  async addProductToSubcategory(
    categoryId: string,
    subcategoryId: string,
    productId: string
  ): Promise<void> {
    const categorySnapshot = await get(ref(realtimeDb, `categories/${categoryId}`));
    const category = categorySnapshot.val() as Category;

    if (category) {
      const updatedSubcategories = category.subcategories.map(sub => {
        if (sub.id === subcategoryId) {
          return {
            ...sub,
            productIds: [...(sub.productIds || []), productId]
          };
        }
        return sub;
      });

      await update(ref(realtimeDb, `categories/${categoryId}`), {
        subcategories: updatedSubcategories
      });
    }
  }

  async removeProductFromSubcategory(
    categoryId: string,
    subcategoryId: string,
    productId: string
  ): Promise<void> {
    const categorySnapshot = await get(ref(realtimeDb, `categories/${categoryId}`));
    const category = categorySnapshot.val() as Category;

    if (category) {
      const updatedSubcategories = category.subcategories.map(sub => {
        if (sub.id === subcategoryId) {
          return {
            ...sub,
            productIds: (sub.productIds || []).filter(id => id !== productId)
          };
        }
        return sub;
      });

      await update(ref(realtimeDb, `categories/${categoryId}`), {
        subcategories: updatedSubcategories
      });
    }
  }

  async updateUser(userId: string, updates: Partial<User>): Promise<void> {
    try {
      await update(ref(realtimeDb, `users/${userId}`), updates);
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  }
}

export const FirebaseService = new FirebaseServiceClass(); 