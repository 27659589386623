import imageCompression from 'browser-image-compression';

export enum ImageSizes {
  PRODUCT = 10 * 1024, // 10KB target
  CATEGORY = 50 * 1024, // 50KB target
  BANNER = 250 * 1024 // 250KB target for better quality banners
}

interface OptimizeImageOptions {
  maxSizeKB: number;
  maxWidthOrHeight?: number;
  isProduct?: boolean;
}

export async function optimizeImage(
  file: File,
  options: OptimizeImageOptions
): Promise<File> {
  try {
    // For product images (target 10KB)
    if (options.isProduct || options.maxSizeKB === ImageSizes.PRODUCT) {
      let quality = 0.9;
      let maxWidth = 1200;
      let optimizedFile = file;

      while (optimizedFile.size > ImageSizes.PRODUCT && quality >= 0.3) {
        const compressionOptions = {
          maxSizeMB: ImageSizes.PRODUCT / 1024 / 1024,
          maxWidthOrHeight: maxWidth,
          useWebWorker: true,
          fileType: file.type,
          initialQuality: quality,
        };

        optimizedFile = await imageCompression(file, compressionOptions);
        quality -= 0.1;
        maxWidth = Math.max(400, maxWidth - 200);
      }

      return optimizedFile;
    }

    // Special handling for banners (250KB with higher quality)
    if (options.maxSizeKB === ImageSizes.BANNER) {
      let quality = 0.95; // Start with very high quality for banners
      let optimizedFile = file;

      while (optimizedFile.size > ImageSizes.BANNER && quality >= 0.7) { // Keep quality higher for banners
        const compressionOptions = {
          maxSizeMB: ImageSizes.BANNER / 1024 / 1024,
          maxWidthOrHeight: 1920, // Maintain high resolution for banners
          useWebWorker: true,
          fileType: file.type,
          initialQuality: quality,
        };

        optimizedFile = await imageCompression(file, compressionOptions);
        quality -= 0.05; // Smaller quality reduction steps for banners
      }

      return optimizedFile;
    }

    // For other images (categories)
    let quality = 0.9;
    let optimizedFile = file;

    while (optimizedFile.size > options.maxSizeKB && quality >= 0.5) {
      const compressionOptions = {
        maxSizeMB: options.maxSizeKB / 1024 / 1024,
        maxWidthOrHeight: options.maxWidthOrHeight || 1920,
        useWebWorker: true,
        fileType: file.type,
        initialQuality: quality,
      };

      optimizedFile = await imageCompression(file, compressionOptions);
      quality -= 0.1;
    }

    return optimizedFile;
  } catch (error) {
    console.error('Error optimizing image:', error);
    return file;
  }
}

// Helper function to check if file is within size limit
export function isWithinSizeLimit(file: File, maxSizeKB: number): boolean {
  return file.size <= maxSizeKB;
}

// Helper to get image dimensions
export async function getImageDimensions(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      resolve({ width: img.width, height: img.height });
    };
    img.src = URL.createObjectURL(file);
  });
} 