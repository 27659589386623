import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';

interface OptimizedImageProps {
  src: string;
  alt: string;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  className?: string;
  loading?: 'lazy' | 'eager';
}

const OptimizedImage: React.FC<OptimizedImageProps> = ({
  src,
  alt,
  width,
  height,
  style,
  className,
  loading = 'lazy'
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>('');

  useEffect(() => {
    const img = new Image();
    img.src = src;
    
    img.onload = () => {
      setImageSrc(src);
      setImageLoaded(true);
    };

    // Preload next image
    return () => {
      img.onload = null;
    };
  }, [src]);

  return (
    <>
      {!imageLoaded && (
        <Skeleton
          variant="rectangular"
          width={width}
          height={height}
          animation="wave"
        />
      )}
      <img
        src={imageSrc}
        alt={alt}
        width={width}
        height={height}
        style={{
          ...style,
          display: imageLoaded ? 'block' : 'none',
          objectFit: 'cover'
        }}
        className={className}
        loading={loading}
        onError={(e) => {
          // Fallback image on error
          (e.target as HTMLImageElement).src = '/placeholder-image.png';
        }}
      />
    </>
  );
};

export default OptimizedImage; 