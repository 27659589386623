import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  CardActions,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Category, Product } from '../types/types';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { FirebaseService } from '../services/firebase.service';
import CategoryForm from './CategoryForm';
import DeleteConfirmDialog from './common/DeleteConfirmDialog';
import PageHeader from './common/PageHeader';
import OptimizedImage from './common/OptimizedImage';

interface CategoriesProps {
  adminId: string;
}

interface CategoryProducts {
  categoryId: string;
  categoryName: string;
  subcategories: {
    id: string;
    name: string;
    products: Product[];
  }[];
}

const Categories: React.FC<CategoriesProps> = ({ adminId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [openForm, setOpenForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<string>('');
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<{
    categoryId: string;
    subcategoryId: string;
    name: string;
  } | null>(null);
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState<CategoryProducts | null>(null);

  const { data: categories = {}, loading: categoriesLoading } = useFirebaseData<Category>(
    FirebaseService.getCategories,
    [refreshTrigger]
  );

  const { data: products = {}, loading: productsLoading } = useFirebaseData<Product>(
    FirebaseService.getProducts,
    [refreshTrigger]
  );

  // Filter categories based on sellerId
  const filteredCategories = React.useMemo(() => {
    return Object.values(categories).filter(category => category.sellerId === adminId);
  }, [categories, adminId]);

  const handleViewSubcategoryProducts = (categoryId: string, subcategoryId: string, subcategoryName: string) => {
    setSelectedSubcategory({ categoryId, subcategoryId, name: subcategoryName });
  };

  const getSubcategoryProducts = (categoryId: string, subcategoryId: string) => {
    return Object.values(products).filter(product => 
      product.categoryId === categoryId && 
      product.subcategoryId === subcategoryId &&
      product.sellerId === adminId
    );
  };

  const handleRefresh = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  const handleEdit = (category: Category) => {
    setSelectedCategory(category);
    setOpenForm(true);
  };

  const handleDelete = (categoryId: string) => {
    setCategoryToDelete(categoryId);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (categoryToDelete) {
      await FirebaseService.deleteCategory(categoryToDelete);
      setDeleteDialogOpen(false);
      setCategoryToDelete('');
    }
  };

  const handleExpandClick = (e: React.MouseEvent, categoryId: string) => {
    e.stopPropagation();
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
  };

  const getProductsBySubcategory = (categoryId: string) => {
    const category = categories[categoryId];
    if (!category) return null;

    return {
      categoryId,
      categoryName: category.name,
      subcategories: (category.subcategories || []).map(sub => ({
        id: sub.id,
        name: sub.name,
        products: Object.values(products).filter(product => 
          product.categoryId === categoryId && 
          product.subcategoryId === sub.id &&
          product.sellerId === adminId
        )
      }))
    };
  };

  const handleCardClick = (categoryId: string) => {
    const categoryProducts = getProductsBySubcategory(categoryId);
    if (categoryProducts) {
      setSelectedCategoryProducts(categoryProducts);
    }
  };

  const handleActionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  if (categoriesLoading || productsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      <Box p={2}>
        <PageHeader 
          title="Categories"
          adminId={adminId}
          action={
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setSelectedCategory(undefined);
                setOpenForm(true);
              }}
              fullWidth={isMobile}
            >
              Add Category
            </Button>
          }
        />
      </Box>

      <Grid container spacing={2}>
        {filteredCategories.map((category) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={category.id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
              onClick={() => handleCardClick(category.id)}
            >
              <OptimizedImage
                src={category.imageUrl}
                alt={category.name}
                width="100%"
                height={160}
                style={{
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px'
                }}
              />
              <CardContent sx={{ flexGrow: 1, p: { xs: 2, sm: 2 } }}>
                <Typography 
                  variant="h6" 
                  noWrap 
                  sx={{ 
                    fontSize: { xs: '1.1rem', sm: '1.25rem' },
                    mb: 1
                  }}
                >
                  {category.name}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    mb: 2
                  }}
                >
                  {category.description || 'No description available'}
                </Typography>
                <Box 
                  mt="auto" 
                  onClick={(e) => e.stopPropagation()}
                  display="flex"
                  flexWrap="wrap"
                  gap={1}
                >
                  <Chip 
                    label={`${category.subcategories?.length || 0} Subcategories`}
                    size="small"
                    onClick={(e) => handleExpandClick(e, category.id)}
                    sx={{ fontSize: { xs: '0.75rem', sm: '0.8125rem' } }}
                  />
                </Box>
              </CardContent>

              <Collapse in={expandedCategory === category.id} timeout="auto" unmountOnExit>
                <CardContent 
                  onClick={(e) => e.stopPropagation()}
                  sx={{ p: { xs: 1, sm: 2 } }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Subcategories
                  </Typography>
                  <List dense sx={{ maxHeight: 200, overflowY: 'auto' }}>
                    {category.subcategories?.map((subcategory) => (
                      <ListItem 
                        key={subcategory.id}
                        button
                        onClick={() => handleViewSubcategoryProducts(
                          category.id,
                          subcategory.id,
                          subcategory.name
                        )}
                        sx={{ 
                          py: 0.5,
                          borderRadius: 1,
                          '&:hover': {
                            backgroundColor: 'action.hover'
                          }
                        }}
                      >
                        <ListItemText 
                          primary={
                            <Typography variant="body2">
                              {subcategory.name}
                            </Typography>
                          }
                          secondary={
                            <Typography variant="caption">
                              {`${subcategory.productIds?.length || 0} Products`}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Collapse>

              <CardActions 
                onClick={handleActionClick}
                sx={{ 
                  justifyContent: 'space-between',
                  p: { xs: 1, sm: 1.5 }
                }}
              >
                <Box>
                  <IconButton 
                    size="small" 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(category);
                    }}
                    sx={{ mr: 1 }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(category.id);
                    }}
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
                <IconButton
                  size="small"
                  onClick={(e) => handleExpandClick(e, category.id)}
                  sx={{
                    transform: expandedCategory === category.id ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.3s'
                  }}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
        
        {filteredCategories.length === 0 && (
          <Grid item xs={12}>
            <Box 
              sx={{ 
                textAlign: 'center', 
                py: 4,
                backgroundColor: 'background.paper',
                borderRadius: 1,
                border: 1,
                borderColor: 'divider'
              }}
            >
              <Typography color="text.secondary">
                No categories found. Click "Add Category" to create one.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={!!selectedCategoryProducts}
        onClose={() => setSelectedCategoryProducts(null)}
        maxWidth="lg"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          p: { xs: 2, sm: 3 },
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Typography variant="h6" sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }}>
            {selectedCategoryProducts?.categoryName}
          </Typography>
          <IconButton 
            onClick={() => setSelectedCategoryProducts(null)}
            size={isMobile ? "medium" : "small"}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 1, sm: 2 } }}>
          {selectedCategoryProducts?.subcategories.map(subcategory => (
            <Box key={subcategory.id} sx={{ mb: { xs: 3, sm: 4 } }}>
              <Typography 
                variant="subtitle1" 
                sx={{
                  mb: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  fontSize: { xs: '1rem', sm: '1.1rem' }
                }}
              >
                {subcategory.name}
                <Chip 
                  label={`${subcategory.products.length} products`}
                  size="small"
                  color="primary"
                  variant="outlined"
                  sx={{ 
                    height: '20px',
                    '& .MuiChip-label': { 
                      px: 1, 
                      fontSize: { xs: '0.65rem', sm: '0.7rem' }
                    }
                  }}
                />
              </Typography>

              {subcategory.products.length > 0 ? (
                <Box sx={{ position: 'relative' }}>
                  <Box sx={{
                    display: 'flex',
                    gap: 1,
                    overflowX: 'auto',
                    scrollBehavior: 'smooth',
                    pb: 1,
                    px: { xs: 1, sm: 0 },
                    '&::-webkit-scrollbar': {
                      height: 6,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      borderRadius: 3,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'primary.main',
                      borderRadius: 3,
                    },
                  }}>
                    {subcategory.products.map((product) => (
                      <Card 
                        key={product.id}
                        sx={{ 
                          minWidth: {
                            xs: '200px',
                            sm: '220px',
                            md: '240px'
                          },
                          flex: '0 0 auto',
                          maxHeight: '320px'
                        }}
                      >
                        <CardMedia
                          component="img"
                          height="140"
                          image={product.imageUrl}
                          alt={product.name}
                          sx={{ objectFit: 'cover' }}
                        />
                        <CardContent sx={{ p: 1.5 }}>
                          <Typography 
                            variant="subtitle1"
                            noWrap
                            sx={{ mb: 0.5 }}
                          >
                            {product.name}
                          </Typography>
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}>
                            <Typography 
                              variant="subtitle1"
                              color="primary"
                              sx={{ fontWeight: 'bold' }}
                            >
                              ₹{product.price}
                            </Typography>
                            {product.discountPercent > 0 && (
                              <Chip
                                label={`${product.discountPercent}% OFF`}
                                color="error"
                                size="small"
                                sx={{ height: '20px', '& .MuiChip-label': { px: 1, fontSize: '0.7rem' } }}
                              />
                            )}
                          </Box>
                          <Box sx={{ mt: 0.5 }}>
                            <Typography 
                              variant="caption" 
                              color="text.secondary"
                              sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 0.5 
                              }}
                            >
                              Stock: {product.stock} {product.measurementUnit}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box 
                  p={2} 
                  bgcolor="background.paper" 
                  borderRadius={1}
                  textAlign="center"
                >
                  <Typography color="text.secondary">
                    No products in this subcategory
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </DialogContent>
      </Dialog>

      {openForm && (
        <CategoryForm
          open={openForm}
          onClose={() => {
            setOpenForm(false);
            setSelectedCategory(undefined);
            handleRefresh();
          }}
          category={selectedCategory}
          adminId={adminId}
          onSuccess={() => {
            handleRefresh();
          }}
        />
      )}

      <DeleteConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={async () => {
          await confirmDelete();
          handleRefresh();
        }}
        title="Delete Category"
        content="Are you sure you want to delete this category? This action cannot be undone."
      />
    </Box>
  );
};

export default Categories; 