import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Box,
  MenuItem,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Product, Category } from '../types/types';
import { FirebaseService } from '../services/firebase.service';
import ImageSelector from './common/ImageSelector';
import { numberInputProps } from './common/types';

interface BulkProductUploadProps {
  open: boolean;
  onClose: () => void;
  adminId: string;
  adminEmail: string;
  categories: Record<string, Category>;
}

interface ProductRow {
  name: string;
  description: string;
  price: number;
  discountPercent: number;
  stock: number;
  imageUrl: string;
  categoryId: string;
  subcategoryId: string;
  measurementUnit: string;
  productQuantity: number;
}

const emptyRow: ProductRow = {
  name: '',
  description: '',
  price: 0,
  discountPercent: 0,
  stock: 0,
  imageUrl: '',
  categoryId: '',
  subcategoryId: '',
  measurementUnit: '',
  productQuantity: 0,
};

const MEASUREMENT_UNITS = ["kg", "g", "l", "ml", "piece", "pack"];

const generateProductId = () => {
  return `PRD${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
};

const generateShortId = () => {
  return `PRD${Math.floor(Math.random() * 100000)}`;
};

const BulkProductUpload: React.FC<BulkProductUploadProps> = ({
  open,
  onClose,
  adminId,
  adminEmail,
  categories,
}) => {
  const [products, setProducts] = useState<ProductRow[]>([{ ...emptyRow }]);
  const [isLoading, setIsLoading] = useState(false);

  const adminCategories = Object.entries(categories)
    .filter(([_, category]) => category.sellerId === adminId)
    .reduce((acc, [id, category]) => {
      acc[id] = category;
      return acc;
    }, {} as Record<string, Category>);

  const handleAddRow = () => {
    setProducts([...products, { ...emptyRow }]);
  };

  const handleRemoveRow = (index: number) => {
    setProducts(products.filter((_, i) => i !== index));
  };

  const handleChange = (index: number, field: keyof ProductRow, value: any) => {
    const updatedProducts = [...products];
    updatedProducts[index] = {
      ...updatedProducts[index],
      [field]: value,
    };
    setProducts(updatedProducts);
  };

  const handleImageSelect = (index: number, url: string) => {
    handleChange(index, 'imageUrl', url);
  };

  const resetForm = () => {
    setProducts([{ ...emptyRow }]);
    setIsLoading(false);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      for (const product of products) {
        if (!product.name || !product.imageUrl || !product.categoryId) continue;

        const selectedCategory = adminCategories[product.categoryId];
        const productData: Product = {
          ...product,
          id: generateProductId(),
          sellerId: adminId,
          adminEmail: adminEmail,
          blocked: false,
          categoryName: selectedCategory.name,
          subcategoryName: selectedCategory.subcategories?.find(
            sub => sub.id === product.subcategoryId
          )?.name || '',
          shortId: generateShortId(),
          stability: 1,
          storagePath: `products/${generateProductId()}`,
        };

        // Save the product
        await FirebaseService.addProduct(productData);

        // Add product reference to subcategory
        if (product.subcategoryId) {
          await FirebaseService.addProductToSubcategory(
            product.categoryId,
            product.subcategoryId,
            productData.id
          );
        }
      }
      handleClose();
    } catch (error) {
      console.error('Error saving products:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
      <DialogTitle>Bulk Product Upload</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, mt: 1 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddRow}
          >
            Add Row
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Discount %</TableCell>
                <TableCell>Stock</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Measurement</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Subcategory</TableCell>
                <TableCell width={200}>Image</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      size="small"
                      value={product.name}
                      onChange={(e) => handleChange(index, 'name', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={product.description}
                      onChange={(e) => handleChange(index, 'description', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      type="number"
                      value={product.price || ''}
                      onChange={(e) => handleChange(index, 'price', Number(e.target.value))}
                      inputProps={numberInputProps}
                      sx={{ width: '100px' }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      type="number"
                      value={product.discountPercent || ''}
                      onChange={(e) => handleChange(index, 'discountPercent', Number(e.target.value))}
                      inputProps={{ ...numberInputProps, max: "100" }}
                      sx={{ width: '80px' }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      type="number"
                      value={product.stock || ''}
                      onChange={(e) => handleChange(index, 'stock', Number(e.target.value))}
                      inputProps={numberInputProps}
                      sx={{ width: '80px' }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      type="number"
                      value={product.productQuantity || ''}
                      onChange={(e) => handleChange(index, 'productQuantity', Number(e.target.value))}
                      inputProps={numberInputProps}
                      sx={{ width: '80px' }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      select
                      size="small"
                      value={product.measurementUnit}
                      onChange={(e) => handleChange(index, 'measurementUnit', e.target.value)}
                    >
                      {MEASUREMENT_UNITS.map((unit) => (
                        <MenuItem key={unit} value={unit}>
                          {unit}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <TextField
                      select
                      size="small"
                      value={product.categoryId}
                      onChange={(e) => handleChange(index, 'categoryId', e.target.value)}
                    >
                      {Object.entries(adminCategories).map(([id, category]) => (
                        <MenuItem key={id} value={id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </TableCell>
                  <TableCell>
                    {product.categoryId && adminCategories[product.categoryId]?.subcategories && (
                      <TextField
                        select
                        size="small"
                        value={product.subcategoryId}
                        onChange={(e) => handleChange(index, 'subcategoryId', e.target.value)}
                      >
                        {adminCategories[product.categoryId].subcategories?.map((sub) => (
                          <MenuItem key={sub.id} value={sub.id}>
                            {sub.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <ImageSelector
                        onImageSelect={(url) => handleImageSelect(index, url)}
                        folder="products"
                      />
                      {product.imageUrl && (
                        <Box 
                          sx={{ 
                            mt: 1, 
                            border: '1px solid #ddd', 
                            borderRadius: 1,
                            p: 0.5,
                            width: 'fit-content'
                          }}
                        >
                          <img
                            src={product.imageUrl}
                            alt="Product"
                            style={{ 
                              width: '100%', 
                              height: 100, 
                              objectFit: 'contain',
                              display: 'block'
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleRemoveRow(index)}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isLoading || products.length === 0}
        >
          {isLoading ? 'Saving...' : 'Save All'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkProductUpload; 