import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import { Admin } from '../types/types';

interface AdminFormProps {
  admin?: Admin | null;
  onSubmit: (admin: Admin) => Promise<void>;
  onCancel: () => void;
}

const AdminForm: React.FC<AdminFormProps> = ({ admin, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState<Partial<Admin>>(
    admin || {
      id: '',
      email: '',
      sellerId: '',
      timestamp: Date.now()
    }
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const id = value.split('@')[0];
      setFormData((prev) => ({ 
        ...prev, 
        email: value,
        id
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.email || !formData.sellerId) {
      alert('Please fill in all required fields');
      return;
    }

    const id = formData.email.split('@')[0];
    if (!id) {
      alert('Invalid email format');
      return;
    }

    const adminData: Admin = {
      id,
      email: formData.email,
      sellerId: formData.sellerId,
      timestamp: formData.timestamp || Date.now()
    };

    await onSubmit(adminData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} p={3}>
      <Typography variant="h5" gutterBottom>
        {admin ? 'Edit Admin' : 'Add New Admin'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            helperText="The part before @ will be used as the admin ID"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Seller ID"
            name="sellerId"
            value={formData.sellerId}
            onChange={handleChange}
            required
            helperText="Unique identifier for the admin's data"
          />
        </Grid>
      </Grid>
      <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AdminForm; 