import { useState, useEffect, useCallback } from 'react';

export function useFirebaseData<T>(
  fetchFunction: () => Promise<Record<string, T>>,
  deps: any[] = []
) {
  const [data, setData] = useState<Record<string, T>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await fetchFunction();
      setData(result);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('An error occurred'));
    } finally {
      setLoading(false);
    }
  }, [fetchFunction]);

  useEffect(() => {
    fetchData();
  }, [fetchData, refreshCounter, ...(deps || [])]);

  const refresh = useCallback(() => {
    setRefreshCounter(prev => prev + 1);
  }, []);

  return { 
    data, 
    loading, 
    error, 
    refresh 
  };
} 