import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, useMediaQuery, Theme } from '@mui/material';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { FirebaseService } from '../services/firebase.service';
import { Admin } from '../types/types';

interface AdminSelectorProps {
  selectedAdmin: string;
  onAdminChange: (adminId: string, email: string) => void;
}

const AdminSelector: React.FC<AdminSelectorProps> = ({ selectedAdmin, onAdminChange }) => {
  const { data: admins = {} } = useFirebaseData<Admin>(FirebaseService.getAdmins);

  const handleChange = (sellerId: string) => {
    const admin = Object.values(admins).find(admin => admin.sellerId === sellerId);
    if (admin) {
      onAdminChange(admin.sellerId, admin.email);
    }
  };

  return (
    <Box className="admin-selector">
      <FormControl 
        fullWidth
        size={useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'small' : 'medium'}
      >
        <InputLabel>Select Admin</InputLabel>
        <Select
          value={selectedAdmin}
          onChange={(e) => handleChange(e.target.value)}
          label="Select Admin"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5, // Show 4.5 items
                width: '90vw', // Make dropdown wider on mobile
                maxWidth: 360,
              },
            },
          }}
        >
          {Object.values(admins).map((admin) => (
            <MenuItem 
              key={admin.sellerId} 
              value={admin.sellerId}
              sx={{
                whiteSpace: 'normal', // Allow text to wrap
                wordBreak: 'break-word',
                py: 1,
              }}
            >
              {admin.email} ({admin.sellerId})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AdminSelector; 