import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import Products from './components/Products';
import Orders from './components/Orders';
import Users from './components/Users';
import Categories from './components/Categories';
import Banners from './components/Banners';
import AdminManagement from './components/AdminManagement';
import AdminSelector from './components/AdminSelector';
import ErrorBoundary from './components/ErrorBoundary';
import Analytics from './components/Analytics';
import { useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import AdminRoute from './components/routes/AdminRoute';
import './styles/App.css';
import { useFirebaseData } from './hooks/useFirebaseData';
import { FirebaseService } from './services/firebase.service';
import { Admin } from './types/types';

// Create a PrivateRoute component
const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentUser, loading } = useAuth();
  
  if (loading) {
    return <CircularProgress />;
  }
  
  return currentUser ? <>{children}</> : <Navigate to="/login" />;
};

function App() {
  const [selectedAdmin, setSelectedAdmin] = useState<string>('');
  const [adminEmail, setAdminEmail] = useState<string>('');
  const { currentUser } = useAuth();
  const { data: admins = {} } = useFirebaseData<Admin>(FirebaseService.getAdmins);
  
  const isMainAdmin = currentUser?.email?.toLowerCase() === 'ravikhandelwal@adinbg.com';

  // Set initial admin ID and email based on the admins collection
  useEffect(() => {
    if (currentUser?.email && admins) {
      // Find the admin entry that matches the current user's email
      const adminEntry = Object.entries(admins).find(
        ([_, admin]) => admin.email.toLowerCase() === currentUser.email?.toLowerCase()
      );

      if (adminEntry) {
        const [_, admin] = adminEntry;
        setSelectedAdmin(admin.sellerId);
        setAdminEmail(admin.email);
      }
    }
  }, [currentUser, admins]);

  const getAdminId = (): string => {
    // Always return the selectedAdmin which is now the sellerId
    return selectedAdmin;
  };

  const getAdminEmail = (): string => {
    if (adminEmail) return adminEmail;
    if (currentUser?.email) return currentUser.email;
    return '';
  };

  const handleAdminSelect = (adminId: string, email: string) => {
    setSelectedAdmin(adminId);
    setAdminEmail(email);
  };

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                  <Sidebar selectedAdmin={getAdminId()} />
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      width: { sm: `calc(100% - 240px)` },
                      mt: { xs: '64px', md: 0 }
                    }}
                  >
                    <div className="main-content-wrapper">
                      {isMainAdmin && window.location.pathname !== '/admin-management' && (
                        <AdminSelector
                          selectedAdmin={selectedAdmin}
                          onAdminChange={handleAdminSelect}
                        />
                      )}
                      <Routes>
                        <Route
                          path="/admin-management"
                          element={
                            <AdminRoute>
                              <AdminManagement />
                            </AdminRoute>
                          }
                        />
                        <Route path="/" element={<Dashboard adminId={getAdminId()} />} />
                        <Route path="/products" element={<Products adminId={getAdminId()} adminEmail={getAdminEmail()} />} />
                        <Route path="/orders" element={<Orders adminId={getAdminId()} />} />
                        <Route path="/users" element={<Users adminId={getAdminId()} />} />
                        <Route path="/categories" element={<Categories adminId={getAdminId()} />} />
                        <Route path="/banners" element={<Banners adminId={getAdminId()} />} />
                        <Route path="/analytics" element={<Analytics adminId={getAdminId()} />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </Routes>
                    </div>
                  </Box>
                </Box>
              </PrivateRoute>
            }
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App; 