import React from 'react';
import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  Chip,
  Collapse,
  Paper,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

interface FilterOption {
  label: string;
  value: string | number | boolean;
}

interface FilterConfig {
  field: string;
  label: string;
  type: 'select' | 'text' | 'number' | 'boolean' | 'range';
  options?: FilterOption[];
}

interface FilterBarProps {
  filters: Record<string, any>;
  config: FilterConfig[];
  onChange: (filters: Record<string, any>) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({ filters, config, onChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showFilters, setShowFilters] = React.useState(!isMobile);

  const handleFilterChange = (field: string, value: any) => {
    onChange({ ...filters, [field]: value });
  };

  const handleClearFilter = (field: string) => {
    const newFilters = { ...filters };
    delete newFilters[field];
    onChange(newFilters);
  };

  const handleClearAll = () => {
    onChange({});
  };

  const activeFiltersCount = Object.keys(filters).length;

  return (
    <Box sx={{ mb: 3 }}>
      <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="space-between"
        mb={2}
      >
        <IconButton 
          onClick={() => setShowFilters(!showFilters)}
          color={activeFiltersCount > 0 ? 'primary' : 'default'}
        >
          <FilterListIcon />
        </IconButton>
        {activeFiltersCount > 0 && (
          <Box display="flex" gap={1} flexWrap="wrap">
            {Object.entries(filters).map(([field, value]) => {
              const fieldConfig = config.find(c => c.field === field);
              if (!fieldConfig) return null;
              
              let displayValue = value;
              if (fieldConfig.type === 'select') {
                const option = fieldConfig.options?.find(o => o.value === value);
                displayValue = option?.label || value;
              }

              return (
                <Chip
                  key={field}
                  label={`${fieldConfig.label}: ${displayValue}`}
                  onDelete={() => handleClearFilter(field)}
                  size="small"
                />
              );
            })}
            <Chip
              label="Clear All"
              onDelete={handleClearAll}
              color="error"
              size="small"
            />
          </Box>
        )}
      </Box>

      <Collapse in={showFilters}>
        <Paper sx={{ p: 2 }}>
          <Box 
            display="flex" 
            gap={2} 
            flexWrap="wrap"
          >
            {config.map((field) => (
              <TextField
                key={field.field}
                select={field.type === 'select'}
                label={field.label}
                value={filters[field.field] || ''}
                onChange={(e) => handleFilterChange(field.field, e.target.value)}
                size="small"
                sx={{ minWidth: 150 }}
              >
                {field.type === 'select' && field.options?.map((option) => (
                  <MenuItem 
                    key={option.value.toString()} 
                    value={option.value.toString()}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ))}
          </Box>
        </Paper>
      </Collapse>
    </Box>
  );
};

export default FilterBar; 