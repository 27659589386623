import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Drawer, 
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  SwipeableDrawer,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Inventory as ProductsIcon,
  ShoppingCart as OrdersIcon,
  People as UsersIcon,
  Category as CategoriesIcon,
  ViewCarousel as BannersIcon,
  AdminPanelSettings as AdminIcon,
  BarChart as BarChartIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';
import '../styles/Sidebar.css';
import { useAuth } from '../contexts/AuthContext';

interface SidebarProps {
  selectedAdmin: string | null;
}

const Sidebar = ({ selectedAdmin }: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMainAdmin = currentUser?.email?.toLowerCase() === 'ravikhandelwal@adinbg.com';

  const menuItems = [
    ...(isMainAdmin ? [
      { path: '/admin-management', label: 'Admin Management', icon: <AdminIcon />, requiresAdmin: false }
    ] : []),
    { path: '/', label: 'Dashboard', icon: <DashboardIcon />, requiresAdmin: true },
    { path: '/products', label: 'Products', icon: <ProductsIcon />, requiresAdmin: true },
    { path: '/orders', label: 'Orders', icon: <OrdersIcon />, requiresAdmin: true },
    { path: '/users', label: 'Users', icon: <UsersIcon />, requiresAdmin: true },
    { path: '/categories', label: 'Categories', icon: <CategoriesIcon />, requiresAdmin: true },
    { path: '/banners', label: 'Banners', icon: <BannersIcon />, requiresAdmin: true },
    { path: '/analytics', label: 'Analytics', icon: <BarChartIcon />, requiresAdmin: true },
  ];

  const isActive = (path: string) => location.pathname === path;

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerContent = (
    <>
      <Box className="logo">
        <Typography variant="h5" component="h1">
          Admin Dashboard
        </Typography>
      </Box>
      <List>
        {menuItems.map((item) => (
          (!item.requiresAdmin || selectedAdmin) && (
            <ListItem
              key={item.path}
              component={Link}
              to={item.path}
              onClick={isMobile ? handleDrawerToggle : undefined}
              className={isActive(item.path) ? 'active' : ''}
              sx={{
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                '&.active': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              <ListItemIcon sx={{ color: 'white' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          )
        ))}
      </List>
    </>
  );

  return (
    <>
      {isMobile && (
        <AppBar 
          position="fixed" 
          sx={{ 
            backgroundColor: '#2c3e50',
            display: { md: 'none' },
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
        >
          <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography 
              variant="h6" 
              noWrap 
              component="div"
              sx={{ 
                fontSize: { xs: '1rem', sm: '1.25rem' },
                flexGrow: 1 
              }}
            >
              Admin Dashboard
            </Typography>
            <IconButton
              color="inherit"
              onClick={handleLogout}
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}

      <Box
        component="nav"
        sx={{
          width: { md: 240 },
          flexShrink: { md: 0 }
        }}
      >
        {isMobile ? (
          <SwipeableDrawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onOpen={() => setMobileOpen(true)}
            onClose={() => setMobileOpen(false)}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                width: '85%',
                maxWidth: 240,
                boxSizing: 'border-box',
                backgroundColor: '#2c3e50',
                color: 'white',
              },
            }}
          >
            {drawerContent}
          </SwipeableDrawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', md: 'block' },
              '& .MuiDrawer-paper': {
                width: 240,
                boxSizing: 'border-box',
                backgroundColor: '#2c3e50',
                color: 'white',
                borderRight: 'none',
                overflowX: 'hidden',
              },
            }}
            open
          >
            {drawerContent}
          </Drawer>
        )}
      </Box>

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1200
          }}
        >
          <Tooltip title="Logout">
            <IconButton
              onClick={handleLogout}
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
                width: 48,
                height: 48,
                boxShadow: 2,
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </>
  );
};

export default Sidebar; 