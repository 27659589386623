import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { FirebaseService } from '../services/firebase.service';
import { Admin } from '../types/types';
import AdminForm from './AdminForm';
import { useAuth } from '../contexts/AuthContext';

const AdminManagement = () => {
  const { data: admins } = useFirebaseData<Admin>(FirebaseService.getAdmins);
  const [open, setOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState<Admin | null>(null);
  const { currentUser } = useAuth();

  const filteredAdmins = useMemo(() => {
    const currentUserEmail = currentUser?.email?.toLowerCase();
    
    if (currentUserEmail === 'ravikhandelwal@adinbg.com') {
      return admins;
    }
    
    return Object.fromEntries(
      Object.entries(admins).filter(([_, admin]) => 
        admin.email.toLowerCase() === currentUserEmail
      )
    );
  }, [admins, currentUser]);

  const handleAdd = () => {
    setSelectedAdmin(null);
    setOpen(true);
  };

  const handleEdit = (admin: Admin) => {
    setSelectedAdmin(admin);
    setOpen(true);
  };

  const handleDelete = async (sellerId: string) => {
    if (window.confirm('Are you sure you want to delete this admin?')) {
      try {
        const adminEntry = Object.entries(filteredAdmins).find(([_, admin]) => admin.sellerId === sellerId);
        if (adminEntry) {
          const [id] = adminEntry;
          await FirebaseService.deleteAdmin(id);
          window.location.reload();
        }
      } catch (error) {
        console.error('Error deleting admin:', error);
        alert('Failed to delete admin');
      }
    }
  };

  const handleSubmit = async (admin: Admin) => {
    try {
      if (selectedAdmin) {
        const adminEntry = Object.entries(filteredAdmins).find(([_, a]) => a.sellerId === selectedAdmin.sellerId);
        if (adminEntry) {
          const [id] = adminEntry;
          await FirebaseService.updateAdmin(id, admin);
        }
      } else {
        const docId = admin.email.split('@')[0];
        await FirebaseService.addAdmin(docId, admin);
      }
      setOpen(false);
      window.location.reload();
    } catch (error) {
      console.error('Error saving admin:', error);
      alert('Failed to save admin');
    }
  };

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Admin Management</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >
          Add Admin
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Seller ID</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(filteredAdmins).map(([id, admin]) => (
              <TableRow key={id}>
                <TableCell>{admin.email}</TableCell>
                <TableCell>{admin.sellerId}</TableCell>
                <TableCell>{new Date(admin.timestamp).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(admin)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(admin.sellerId)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <AdminForm
          admin={selectedAdmin}
          onSubmit={handleSubmit}
          onCancel={() => setOpen(false)}
        />
      </Dialog>
    </Box>
  );
};

export default AdminManagement; 