import React, { useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { Order, Category } from '../types/types';
import { FirebaseService } from '../services/firebase.service';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { WorkBook, utils, writeFile } from 'xlsx';
import ShareIcon from '@mui/icons-material/Share';
import PageHeader from './common/PageHeader';

interface AnalyticsProps {
  adminId: string;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];
const TIME_RANGES = ['Today', 'Last 7 Days', 'Last 30 Days', 'This Month', 'This Year'];

interface DailyStats {
  date: string;
  orders: number;
  revenue: number;
  items: number;
  averageOrderValue: number;
}

const Analytics: React.FC<AnalyticsProps> = ({ adminId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [timeRange, setTimeRange] = useState('Last 7 Days');
  const { data: orders = {}, loading: ordersLoading } = useFirebaseData<Order>(
    FirebaseService.getOrders
  );
  const { data: categories = {}, loading: usersLoading } = useFirebaseData<Category>(
    FirebaseService.getCategories
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const analytics = useMemo(() => {
    const adminOrders = Object.values(orders).filter(order => order.sellerId === adminId);
    const now = Date.now();
    const msPerDay = 24 * 60 * 60 * 1000;

    // Filter orders based on time range
    const filteredOrders = adminOrders.filter(order => {
      const orderDate = order.date;
      switch (timeRange) {
        case 'Today':
          return orderDate > now - msPerDay;
        case 'Last 7 Days':
          return orderDate > now - 7 * msPerDay;
        case 'Last 30 Days':
          return orderDate > now - 30 * msPerDay;
        case 'This Month':
          const d = new Date(now);
          return new Date(orderDate).getMonth() === d.getMonth();
        case 'This Year':
          return new Date(orderDate).getFullYear() === new Date(now).getFullYear();
        default:
          return true;
      }
    });

    // Calculate total revenue
    const totalRevenue = filteredOrders.reduce((sum, order) => sum + order.totalAmount, 0);
    const totalOrders = filteredOrders.length;
    const averageOrderValue = totalOrders ? totalRevenue / totalOrders : 0;

    // Category-wise sales
    const categorySales = filteredOrders.reduce((acc, order) => {
      order.products.forEach(product => {
        // Get category name from categories data
        const category = Object.values(categories).find(
          cat => cat.id === product.productId.split('_')[1]
        );
        const categoryName = category?.name || 'Unknown Category';

        if (!acc[categoryName]) {
          acc[categoryName] = {
            revenue: 0,
            quantity: 0,
            orders: 0,
          };
        }
        // Use discounted price if available
        const effectivePrice = product.productDiscountedPrice || product.productPrice;
        acc[categoryName].revenue += (effectivePrice || 0) * product.quantity;
        acc[categoryName].quantity += product.quantity;
        acc[categoryName].orders += 1;
      });
      return acc;
    }, {} as Record<string, { revenue: number; quantity: number; orders: number }>);

    // Daily revenue data
    const dailyRevenue = filteredOrders.reduce((acc, order) => {
      const date = new Date(order.date).toLocaleDateString();
      acc[date] = (acc[date] || 0) + order.totalAmount;
      return acc;
    }, {} as Record<string, number>);

    // Status distribution
    const orderStatus = filteredOrders.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return {
      totalRevenue,
      totalOrders,
      averageOrderValue,
      categorySales,
      dailyRevenue,
      orderStatus,
      filteredOrders,
    };
  }, [orders, categories, adminId, timeRange]);

  const revenueChartData = Object.entries(analytics.dailyRevenue).map(([date, amount]) => ({
    date,
    revenue: amount,
  }));

  const categoryChartData = Object.entries(analytics.categorySales).map(([name, data]) => ({
    name,
    value: data.revenue,
  }));

  const statusChartData = Object.entries(analytics.orderStatus).map(([status, count]) => ({
    name: status,
    value: count,
  }));

  // Daily stats calculation
  const dailyStats = useMemo(() => {
    const stats = analytics.filteredOrders.reduce<Record<string, DailyStats>>((acc, order) => {
      const date = new Date(order.date).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = {
          date,
          orders: 0,
          revenue: 0,
          items: 0,
          averageOrderValue: 0,
        };
      }
      
      acc[date].orders++;
      // Use the order's total amount which should already include discounts
      acc[date].revenue += order.totalAmount;
      acc[date].items += order.products.reduce((sum: number, p) => sum + p.quantity, 0);
      acc[date].averageOrderValue = acc[date].revenue / acc[date].orders;
      
      return acc;
    }, {});

    return Object.values(stats).sort((a, b) => 
      new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }, [analytics.filteredOrders]);

  // Function to generate and download report
  const downloadReport = () => {
    // Create workbook
    const wb: WorkBook = {
      SheetNames: [],
      Sheets: {},
      Props: {
        LastAuthor: "Admin Dashboard",
        Author: "Admin Dashboard",
        CreatedDate: new Date()
      }
    };

    // Summary sheet
    const summaryData = [
      ['Summary Report', ''],
      ['Time Range', timeRange],
      ['Total Revenue', `₹${analytics.totalRevenue}`],
      ['Total Orders', analytics.totalOrders],
      ['Average Order Value', `₹${analytics.averageOrderValue.toFixed(2)}`],
      ['', ''],
      ['Category-wise Revenue', ''],
      ...Object.entries(analytics.categorySales).map(([category, data]) => [
        category,
        `₹${data.revenue}`,
        `${data.quantity} items`,
        `${data.orders} orders`
      ]),
    ];

    const summaryWS = utils.aoa_to_sheet(summaryData);
    wb.SheetNames.push('Summary');
    wb.Sheets['Summary'] = summaryWS;

    // Daily stats sheet
    const dailyData = [
      ['Date', 'Orders', 'Revenue', 'Items Sold', 'Average Order Value'],
      ...dailyStats.map((day: DailyStats) => [
        day.date,
        day.orders,
        `₹${day.revenue}`,
        day.items,
        `₹${day.averageOrderValue.toFixed(2)}`
      ])
    ];

    const dailyWS = utils.aoa_to_sheet(dailyData);
    wb.SheetNames.push('Daily Stats');
    wb.Sheets['Daily Stats'] = dailyWS;

    // Download file
    writeFile(wb, `Analytics_Report_${timeRange.replace(/\s/g, '_')}.xlsx`);
  };

  // Add share function
  const handleShare = async () => {
    try {
      // Try to use the native share API first
      if (navigator.share) {
        await navigator.share({
          title: 'Analytics Dashboard',
          text: 'Check out our analytics dashboard',
          url: window.location.href,
        });
      } else {
        // Fallback to clipboard copy
        await navigator.clipboard.writeText(window.location.href);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <Box p={3}>
      <PageHeader 
        title="Analytics Dashboard"
        adminId={adminId}
        action={
          <Box 
            display="flex" 
            gap={2}
            flexDirection={{ xs: 'column', sm: 'row' }}
            width={{ xs: '100%', sm: 'auto' }}
          >
            <FormControl sx={{ minWidth: { xs: '100%', sm: 150 } }}>
              <InputLabel>Time Range</InputLabel>
              <Select
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
                label="Time Range"
                size="small"
                fullWidth
              >
                {TIME_RANGES.map(range => (
                  <MenuItem key={range} value={range}>{range}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box 
              display="flex" 
              gap={1}
              flexDirection={{ xs: 'row', sm: 'row' }}
              width={{ xs: '100%', sm: 'auto' }}
            >
              <Button
                variant="outlined"
                startIcon={<FileDownloadIcon />}
                onClick={downloadReport}
                size="small"
                fullWidth={isMobile}
              >
                Download
              </Button>
              <Button
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={handleShare}
                size="small"
                fullWidth={isMobile}
              >
                Share
              </Button>
            </Box>
          </Box>
        }
      />

      {/* Summary Cards */}
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography color="textSecondary" variant="subtitle2">Total Revenue</Typography>
              <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                ₹{analytics.totalRevenue.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography color="textSecondary" variant="subtitle2">Total Orders</Typography>
              <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                {analytics.totalOrders}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography color="textSecondary" variant="subtitle2">Average Order Value</Typography>
              <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
                ₹{analytics.averageOrderValue.toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={2}>
        {/* Revenue Trend */}
        <Grid item xs={12}>
          <Paper sx={{ p: { xs: 1, sm: 2 } }}>
            <Typography variant="h6" gutterBottom>Revenue Trend</Typography>
            <Box sx={{ width: '100%', height: { xs: 250, sm: 300 } }}>
              <ResponsiveContainer>
                <LineChart data={revenueChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="date" 
                    tick={{ fontSize: isMobile ? 10 : 12 }}
                    angle={isMobile ? -45 : 0}
                    textAnchor={isMobile ? 'end' : 'middle'}
                    height={isMobile ? 60 : 30}
                  />
                  <YAxis tick={{ fontSize: isMobile ? 10 : 12 }} />
                  <Tooltip />
                  <Legend wrapperStyle={{ fontSize: isMobile ? 10 : 12 }} />
                  <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Category Distribution */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: { xs: 1, sm: 2 } }}>
            <Typography variant="h6" gutterBottom>Category-wise Revenue</Typography>
            <Box sx={{ width: '100%', height: { xs: 250, sm: 300 } }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={categoryChartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={isMobile ? 60 : 80}
                    label={!isMobile}
                  >
                    {categoryChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend 
                    wrapperStyle={{ fontSize: isMobile ? 10 : 12 }}
                    layout={isMobile ? "horizontal" : "vertical"}
                    align={isMobile ? "center" : "right"}
                    verticalAlign={isMobile ? "bottom" : "middle"}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Order Status */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: { xs: 1, sm: 2 } }}>
            <Typography variant="h6" gutterBottom>Order Status Distribution</Typography>
            <Box sx={{ width: '100%', height: { xs: 250, sm: 300 } }}>
              <ResponsiveContainer>
                <BarChart data={statusChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="name" 
                    tick={{ fontSize: isMobile ? 10 : 12 }}
                    angle={isMobile ? -45 : 0}
                    textAnchor={isMobile ? 'end' : 'middle'}
                    height={isMobile ? 60 : 30}
                  />
                  <YAxis tick={{ fontSize: isMobile ? 10 : 12 }} />
                  <Tooltip />
                  <Legend wrapperStyle={{ fontSize: isMobile ? 10 : 12 }} />
                  <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Daily Stats Table */}
      <Paper sx={{ mt: 2, p: { xs: 1, sm: 2 } }}>
        <Typography variant="h6" gutterBottom>Daily Revenue Stats</Typography>
        <TableContainer>
          <Table 
            size={isMobile ? "small" : "medium"} 
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Orders</TableCell>
                {!isMobile && <TableCell align="right">Items Sold</TableCell>}
                <TableCell align="right">Revenue</TableCell>
                {!isMobile && <TableCell align="right">Avg. Order Value</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {dailyStats.map((day: DailyStats) => (
                <TableRow key={day.date}>
                  <TableCell>{day.date}</TableCell>
                  <TableCell align="right">{day.orders}</TableCell>
                  {!isMobile && <TableCell align="right">{day.items}</TableCell>}
                  <TableCell align="right">₹{day.revenue.toLocaleString()}</TableCell>
                  {!isMobile && (
                    <TableCell align="right">₹{day.averageOrderValue.toFixed(2)}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ 
          vertical: 'bottom', 
          horizontal: isMobile ? 'center' : 'right' 
        }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity="success"
          variant="filled"
        >
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Analytics; 