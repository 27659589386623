import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';
import { Product, Category } from '../types/types';
import { FirebaseService } from '../services/firebase.service';
import { ImageSizes } from '../utils/imageOptimizer';
import ImageSelector from './common/ImageSelector';
import { numberInputProps } from './common/types';

interface ProductFormProps {
  open: boolean;
  onClose: () => void;
  product?: Product;
  adminId: string;
  adminEmail: string;
  categories: Record<string, Category>;
}

const MEASUREMENT_UNITS = ["kg", "g", "l", "ml", "piece", "pack"];

const generateProductId = () => {
  return `PRD${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
};

const generateShortId = () => {
  return `PRD${Math.floor(Math.random() * 100000)}`;
};

const ProductForm: React.FC<ProductFormProps> = ({
  open,
  onClose,
  product,
  adminId,
  adminEmail,
  categories,
}) => {
  console.log('ProductForm received product:', product); // Debug log

  const [formData, setFormData] = useState<Product>(() => {
    if (product) {
      return { ...product };
    }
    // Default values for new product
    return {
      id: generateProductId(),
      name: '',
      description: '',
      price: 0,
      discountPercent: 0,
      stock: 0,
      imageUrl: '',
      categoryId: '',
      subcategoryId: '',
      categoryName: '',
      subcategoryName: '',
      measurementUnit: '',
      productQuantity: 0,
      sellerId: adminId,
      adminEmail: adminEmail,
      blocked: false,
      shortId: generateShortId(),
      stability: 1,
      storagePath: `products/${generateProductId()}`,
    };
  });

  // Reset form when product prop changes
  useEffect(() => {
    if (product) {
      setFormData({ ...product });
    } else {
      setFormData({
        id: generateProductId(),
        name: '',
        description: '',
        price: 0,
        discountPercent: 0,
        stock: 0,
        imageUrl: '',
        categoryId: '',
        subcategoryId: '',
        categoryName: '',
        subcategoryName: '',
        measurementUnit: '',
        productQuantity: 0,
        sellerId: adminId,
        adminEmail: adminEmail,
        blocked: false,
        shortId: generateShortId(),
        stability: 1,
        storagePath: `products/${generateProductId()}`,
      });
    }
  }, [product, adminId, adminEmail]);

  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Filter categories for the current admin
  const adminCategories = Object.entries(categories)
    .filter(([_, category]) => category.sellerId === adminId)
    .reduce((acc, [id, category]) => {
      acc[id] = category;
      return acc;
    }, {} as Record<string, Category>);

  const handleImageSelect = async (fileOrUrl: File | string) => {
    setIsLoading(true);
    try {
      let imageUrl: string;
      if (fileOrUrl instanceof File) {
        // Upload new file
        imageUrl = await FirebaseService.uploadProductImage(fileOrUrl);
      } else {
        // Use existing URL from storage
        imageUrl = fileOrUrl;
      }
      setFormData(prev => ({ ...prev, imageUrl }));
      setImageError('');
    } catch (error) {
      console.error('Error processing image:', error);
      setImageError('Failed to process image. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      id: generateProductId(),
      name: '',
      description: '',
      price: 0,
      discountPercent: 0,
      stock: 0,
      imageUrl: '',
      categoryId: '',
      subcategoryId: '',
      categoryName: '',
      subcategoryName: '',
      measurementUnit: '',
      productQuantity: 0,
      sellerId: adminId,
      adminEmail: adminEmail,
      blocked: false,
      shortId: generateShortId(),
      stability: 1,
      storagePath: `products/${generateProductId()}`,
    });
    setImageError('');
  };

  // Handle form close
  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = async () => {
    if (!formData.name || !formData.imageUrl || !formData.categoryId) {
      return;
    }

    setIsLoading(true);
    try {
      const selectedCategory = adminCategories[formData.categoryId];
      const productData: Product = {
        ...formData as Product,
        categoryName: selectedCategory.name,
        subcategoryName: selectedCategory.subcategories?.find(
          sub => sub.id === formData.subcategoryId
        )?.name || '',
        shortId: product?.shortId || `PRD${Date.now()}`,
        stability: product?.stability || 1,
      };

      // If editing a product, remove it from old subcategory if changed
      if (product?.id && product.subcategoryId !== formData.subcategoryId) {
        const oldCategory = adminCategories[product.categoryId];
        const oldSubcategory = oldCategory?.subcategories?.find(
          sub => sub.id === product.subcategoryId
        );
        if (oldSubcategory) {
          await FirebaseService.removeProductFromSubcategory(
            product.categoryId,
            product.subcategoryId,
            product.id
          );
        }
      }

      // Save the product
      if (product?.id) {
        await FirebaseService.updateProduct(product.id, productData);
      } else {
        await FirebaseService.addProduct(productData);
      }

      // Add product reference to new subcategory
      if (formData.subcategoryId) {
        await FirebaseService.addProductToSubcategory(
          formData.categoryId,
          formData.subcategoryId,
          product?.id || productData.id
        );
      }

      handleClose();
    } catch (error) {
      console.error('Error saving product:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedCategory = formData.categoryId ? adminCategories[formData.categoryId] : null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{product ? 'Edit Product' : 'Add New Product'}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            fullWidth
            label="Product Name"
            value={formData.name || ''}
            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
          />

          <TextField
            fullWidth
            label="Description"
            value={formData.description || ''}
            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            multiline
            rows={3}
          />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              type="number"
              label="Price"
              value={formData.price || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, price: Number(e.target.value) }))}
              inputProps={numberInputProps}
            />
            <TextField
              fullWidth
              type="number"
              label="Discount %"
              value={formData.discountPercent || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, discountPercent: Number(e.target.value) }))}
              inputProps={{ ...numberInputProps, max: "100" }}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              type="number"
              label="Stock"
              value={formData.stock || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, stock: Number(e.target.value) }))}
              inputProps={numberInputProps}
            />
            <TextField
              fullWidth
              type="number"
              label="Product Quantity"
              value={formData.productQuantity || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, productQuantity: Number(e.target.value) }))}
              inputProps={numberInputProps}
            />
          </Box>

          <TextField
            select
            fullWidth
            label="Measurement Unit"
            value={formData.measurementUnit}
            onChange={(e) => setFormData(prev => ({ ...prev, measurementUnit: e.target.value }))}
          >
            {MEASUREMENT_UNITS.map((unit) => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Category"
            value={formData.categoryId}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              categoryId: e.target.value,
              subcategoryId: ''
            }))}
          >
            {Object.entries(adminCategories).map(([id, category]) => (
              <MenuItem key={id} value={id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>

          {/* Show warning if no categories are available */}
          {Object.keys(adminCategories).length === 0 && (
            <Typography color="warning.main" variant="body2">
              No categories found. Please create a category first.
            </Typography>
          )}

          {selectedCategory && selectedCategory.subcategories && (
            <TextField
              select
              fullWidth
              label="Subcategory"
              value={formData.subcategoryId}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                subcategoryId: e.target.value
              }))}
            >
              {selectedCategory.subcategories.map((sub) => (
                <MenuItem key={sub.id} value={sub.id}>
                  {sub.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          <Box>
            <ImageSelector
              onImageSelect={handleImageSelect}
              folder="products"
              maxSize={ImageSizes.PRODUCT}
            />
            {formData.imageUrl && (
              <Box mt={2}>
                <img
                  src={formData.imageUrl}
                  alt="Product"
                  style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                />
              </Box>
            )}
            {imageError && (
              <Typography color="error" variant="caption" display="block" sx={{ mt: 1 }}>
                {imageError}
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            isLoading || 
            !formData.name || 
            !formData.imageUrl || 
            !formData.categoryId ||
            Object.keys(adminCategories).length === 0
          }
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductForm; 