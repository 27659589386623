import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Switch,
  Chip,
  Grid,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Banner } from '../types/types';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { FirebaseService } from '../services/firebase.service';
import BannerForm from './BannerForm';
import DeleteConfirmDialog from './common/DeleteConfirmDialog';
import PageHeader from './common/PageHeader';

interface BannersProps {
  adminId: string;
}

const Banners: React.FC<BannersProps> = ({ adminId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { data: banners = {}, loading } = useFirebaseData<Banner>(
    FirebaseService.getBanners,
    [refreshTrigger]
  );
  const [openForm, setOpenForm] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState<Banner | undefined>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bannerToDelete, setBannerToDelete] = useState<string>('');

  const adminBanners = Object.entries(banners)
    .filter(([_, banner]) => banner.sellerId === adminId)
    .sort((a, b) => b[1].activationTime - a[1].activationTime);

  const handleAdd = () => {
    setSelectedBanner(undefined);
    setOpenForm(true);
  };

  const handleEdit = (banner: Banner) => {
    setSelectedBanner(banner);
    setOpenForm(true);
  };

  const handleDelete = (bannerId: string) => {
    setBannerToDelete(bannerId);
    setDeleteDialogOpen(true);
  };

  const handleToggleActive = async (bannerId: string, currentStatus: boolean) => {
    try {
      await FirebaseService.updateBanner(bannerId, { active: !currentStatus });
      setRefreshTrigger(prev => prev + 1);
    } catch (error) {
      console.error('Error toggling banner status:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      <Box p={2}>
        <PageHeader 
          title="Banners"
          adminId={adminId}
          action={
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAdd}
              fullWidth={isMobile}
            >
              Add Banner
            </Button>
          }
        />
      </Box>

      <Grid container spacing={2}>
        {adminBanners.map(([id, banner]) => (
          <Grid item xs={12} sm={6} md={4} key={id}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height={isMobile ? 200 : 160}
                image={banner.imageUrl}
                alt={banner.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent sx={{ flexGrow: 1, p: { xs: 2, sm: 2 } }}>
                <Typography 
                  variant="h6" 
                  gutterBottom
                  sx={{ fontSize: { xs: '1.1rem', sm: '1.25rem' } }}
                >
                  {banner.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    mb: 2
                  }}
                >
                  {banner.description}
                </Typography>

                <Box sx={{ mb: 2 }}>
                  <Button
                    href={banner.actionUrl}
                    target="_blank"
                    size="small"
                    variant="outlined"
                    sx={{ 
                      width: '100%',
                      textTransform: 'none',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {banner.actionUrl}
                  </Button>
                </Box>

                <Typography variant="caption" display="block" color="text.secondary" gutterBottom>
                  Active Period:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  From: {new Date(banner.activationTime).toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  To: {new Date(banner.deactivationTime).toLocaleString()}
                </Typography>

                <Box 
                  sx={{ 
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Switch
                      checked={banner.active}
                      onChange={() => handleToggleActive(id, banner.active)}
                      size="small"
                    />
                    <Chip
                      label={banner.active ? 'Active' : 'Inactive'}
                      color={banner.active ? 'success' : 'default'}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <IconButton 
                      onClick={() => handleEdit(banner)} 
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                      onClick={() => handleDelete(id)} 
                      size="small"
                      color="error"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {adminBanners.length === 0 && (
          <Grid item xs={12}>
            <Box 
              sx={{ 
                textAlign: 'center', 
                py: 4,
                backgroundColor: 'background.paper',
                borderRadius: 1,
                border: 1,
                borderColor: 'divider'
              }}
            >
              <Typography color="text.secondary">
                No banners found. Click "Add Banner" to create one.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>

      {openForm && (
        <BannerForm
          open={openForm}
          onClose={() => {
            setOpenForm(false);
            setSelectedBanner(undefined);
            setRefreshTrigger(prev => prev + 1);
          }}
          banner={selectedBanner}
          adminId={adminId}
        />
      )}

      <DeleteConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={async () => {
          if (bannerToDelete) {
            await FirebaseService.deleteBanner(bannerToDelete);
            setDeleteDialogOpen(false);
            setBannerToDelete('');
            setRefreshTrigger(prev => prev + 1);
          }
        }}
        title="Delete Banner"
        content="Are you sure you want to delete this banner? This action cannot be undone."
      />
    </Box>
  );
};

export default Banners; 