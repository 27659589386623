import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  CircularProgress,
  Box,
  Typography
} from '@mui/material';
import OptimizedImage from './OptimizedImage';

interface ImageSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  images: string[];
  onSelect: (url: string) => void;
  loading: boolean;
}

const ImageSelectionDialog: React.FC<ImageSelectionDialogProps> = ({
  open,
  onClose,
  images,
  onSelect,
  loading
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Choose Image from Storage</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" p={3}>
            <CircularProgress />
          </Box>
        ) : images.length === 0 ? (
          <Box p={3} textAlign="center">
            <Typography>No images found in storage</Typography>
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {images.map((url, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardActionArea onClick={() => {
                    onSelect(url);
                    onClose();
                  }}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={url}
                      alt={`Stored image ${index + 1}`}
                      sx={{ objectFit: 'cover' }}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImageSelectionDialog; 