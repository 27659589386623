import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  useTheme,
  useMediaQuery,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Tab,
  Tabs,
  Dialog as ConfirmDialog,
  DialogTitle as ConfirmDialogTitle,
  DialogContent as ConfirmDialogContent,
  DialogActions as ConfirmDialogActions,
  Alert,
  Snackbar,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { User } from '../types/types';
import { FirebaseService } from '../services/firebase.service';
import { useFirebaseData } from '../hooks/useFirebaseData';
import PageHeader from './common/PageHeader';

interface UsersProps {
  adminId: string;
}

interface PasswordResetState {
  userId: string;
  userName: string;
  newPassword: string;
  showPassword: boolean;
}

const Users: React.FC<UsersProps> = ({ adminId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tabValue, setTabValue] = useState(0);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState<{
    type: 'delete' | 'reset' | 'block' | 'unblock';
    userId: string;
    userName: string;
  } | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [editUserData, setEditUserData] = useState({ name: '', address: '' });
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({ open: false, message: '', severity: 'success' });
  const [passwordReset, setPasswordReset] = useState<PasswordResetState | null>(null);

  const { data: activeUsers = {}, loading: activeLoading, refresh: refreshActiveUsers } = useFirebaseData<User>(
    FirebaseService.getUsers
  );

  const { data: pendingUsers = {}, loading: pendingLoading, refresh: refreshPendingUsers } = useFirebaseData<User>(
    FirebaseService.getPendingUsers
  );

  const filteredActiveUsers = useMemo(() => {
    return Object.entries(activeUsers)
      .filter(([_, user]) => 
        user && typeof user === 'object' && 'sellerId' in user && user.sellerId === adminId
      );
  }, [activeUsers, adminId]);

  const filteredPendingUsers = useMemo(() => {
    return Object.entries(pendingUsers)
      .filter(([_, user]) => 
        user && 
        typeof user === 'object' && 
        'sellerId' in user && 
        user.sellerId === adminId &&
        user.status === 'pending'
      );
  }, [pendingUsers, adminId]);

  const handleEdit = (userId: string, userData: User) => {
    setSelectedUser(userId);
    setEditUserData({ name: userData.name, address: userData.address || '' });
    setEditDialogOpen(true);
  };

  const handleConfirmEdit = async () => {
    if (!selectedUser) return;
    try {
      await FirebaseService.updateUser(selectedUser, editUserData);
      setEditDialogOpen(false);
      showSnackbar('User updated successfully', 'success');
      refreshUsers();
    } catch (error) {
      console.error('Error updating user:', error);
      showSnackbar('Failed to update user', 'error');
    }
  };

  const handleToggleBlock = (userId: string, userName: string, currentStatus: boolean) => {
    setConfirmAction({
      type: currentStatus ? 'unblock' : 'block',
      userId,
      userName
    });
    setConfirmDialogOpen(true);
  };

  const handleResetPassword = (userId: string, userName: string) => {
    setPasswordReset({
      userId,
      userName,
      newPassword: '',
      showPassword: false
    });
  };

  const handlePasswordReset = async () => {
    if (!passwordReset || !passwordReset.newPassword) return;

    try {
      await FirebaseService.updateUserPassword(
        passwordReset.userId, 
        passwordReset.newPassword
      );
      showSnackbar('Password reset successfully', 'success');
      setPasswordReset(null);
      refreshUsers();
    } catch (error) {
      console.error('Error resetting password:', error);
      showSnackbar('Failed to reset password', 'error');
    }
  };

  const handleDeleteUser = (userId: string, userName: string) => {
    setConfirmAction({
      type: 'delete',
      userId,
      userName
    });
    setConfirmDialogOpen(true);
  };

  const handleConfirmAction = async () => {
    if (!confirmAction) return;

    try {
      switch (confirmAction.type) {
        case 'block':
        case 'unblock':
          await FirebaseService.updateUser(confirmAction.userId, { 
            blocked: confirmAction.type === 'block' 
          });
          showSnackbar(
            `User ${confirmAction.type === 'block' ? 'blocked' : 'unblocked'} successfully`,
            'success'
          );
          refreshUsers();
          break;

        case 'reset':
          await FirebaseService.resetUserPassword(confirmAction.userId);
          showSnackbar('Password reset successfully', 'success');
          refreshUsers();
          break;

        case 'delete':
          await FirebaseService.deleteUser(confirmAction.userId);
          showSnackbar('User deleted successfully', 'success');
          refreshUsers();
          break;
      }
    } catch (error) {
      console.error('Error performing action:', error);
      showSnackbar(`Failed to ${confirmAction.type} user`, 'error');
    } finally {
      setConfirmDialogOpen(false);
      setConfirmAction(null);
    }
  };

  const handleApproveUser = async (userId: string) => {
    try {
      await FirebaseService.approveUser(userId);
      showSnackbar('User approved successfully', 'success');
      refreshUsers();
    } catch (error) {
      console.error('Error approving user:', error);
      showSnackbar('Failed to approve user', 'error');
    }
  };

  const handleDenyUser = async (userId: string) => {
    try {
      await FirebaseService.denyUser(userId);
      showSnackbar('User denied successfully', 'success');
      refreshUsers();
    } catch (error) {
      console.error('Error denying user:', error);
      showSnackbar('Failed to deny user', 'error');
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbar({ open: true, message, severity });
  };

  const renderUserActions = (id: string, user: User) => (
    <Box display="flex" gap={1}>
      <IconButton 
        onClick={() => handleEdit(id, user)}
        color="primary"
        size="small"
        title="Edit user"
      >
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() => handleToggleBlock(id, user.name, !!user.blocked)}
        color={user.blocked ? 'error' : 'success'}
        size="small"
        title={user.blocked ? 'Unblock user' : 'Block user'}
      >
        {user.blocked ? <BlockIcon /> : <CheckCircleIcon />}
      </IconButton>
      <IconButton
        onClick={() => handleResetPassword(id, user.name)}
        color="warning"
        size="small"
        title="Reset password"
      >
        <LockResetIcon />
      </IconButton>
      <IconButton
        onClick={() => handleDeleteUser(id, user.name)}
        color="error"
        size="small"
        title="Delete user"
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  const renderUserInfo = (user: User) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography component="span" color="text.secondary" variant="body2">
          Phone:
        </Typography>
        <Typography component="span" variant="body2">
          {user.phoneNumber}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography component="span" color="text.secondary" variant="body2">
          Address:
        </Typography>
        <Typography 
          component="span" 
          variant="body2"
          sx={{ 
            wordBreak: 'break-word',
            flex: 1 
          }}
        >
          {user.address || 'Not provided'}
        </Typography>
      </Box>
    </Box>
  );

  const refreshUsers = () => {
    refreshActiveUsers();
    refreshPendingUsers();
  };

  return (
    <Box p={2}>
      <PageHeader 
        title="Users Management"
        adminId={adminId}
      />

      <Tabs
        value={tabValue}
        onChange={(_, newValue) => setTabValue(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Active Users" />
        <Tab 
          label={
            <Box display="flex" alignItems="center">
              Pending Users
              {filteredPendingUsers.length > 0 && (
                <Chip
                  label={filteredPendingUsers.length}
                  color="error"
                  size="small"
                  sx={{ ml: 1 }}
                />
              )}
            </Box>
          }
        />
      </Tabs>

      {/* Active Users Tab */}
      {tabValue === 0 && (
        <>
          {activeLoading ? (
            <Box display="flex" justifyContent="center" p={3}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!isMobile ? (
                <Grid container spacing={2}>
                  {filteredActiveUsers.map(([id, user]) => (
                    <Grid item xs={12} md={6} lg={4} key={id}>
                      <Card>
                        <CardContent>
                          <Box sx={{ mb: 2 }}>
                            <Box 
                              display="flex" 
                              justifyContent="space-between" 
                              alignItems="flex-start"
                              mb={2}
                            >
                              <Box sx={{ flex: 1, mr: 2 }}>
                                <Typography variant="h6" component="div" gutterBottom>
                                  {user.name}
                                </Typography>
                                <Chip
                                  icon={user.blocked ? <BlockIcon /> : <CheckCircleIcon />}
                                  label={user.blocked ? 'Blocked' : 'Active'}
                                  color={user.blocked ? 'error' : 'success'}
                                  size="small"
                                />
                              </Box>
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                {renderUserActions(id, user)}
                              </Box>
                            </Box>
                            {renderUserInfo(user)}
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                  {filteredActiveUsers.length === 0 && (
                    <Grid item xs={12}>
                      <Box textAlign="center" py={3}>
                        <Typography color="textSecondary">
                          No active users found
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <List>
                  {filteredActiveUsers.map(([id, user]) => (
                    <React.Fragment key={id}>
                      <ListItem
                        sx={{
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          gap: 1,
                          py: 2
                        }}
                      >
                        <Box sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between', 
                          alignItems: 'flex-start',
                          mb: 1
                        }}>
                          <Box sx={{ flex: 1, mr: 2 }}>
                            <Typography variant="subtitle1" component="div" gutterBottom>
                              {user.name}
                            </Typography>
                            <Chip
                              icon={user.blocked ? <BlockIcon /> : <CheckCircleIcon />}
                              label={user.blocked ? 'Blocked' : 'Active'}
                              color={user.blocked ? 'error' : 'success'}
                              size="small"
                            />
                          </Box>
                          <Box>
                            {renderUserActions(id, user)}
                          </Box>
                        </Box>
                        <Box>
                          {renderUserInfo(user)}
                        </Box>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                  {filteredActiveUsers.length === 0 && (
                    <Box textAlign="center" py={3}>
                      <Typography color="textSecondary">
                        No active users found
                      </Typography>
                    </Box>
                  )}
                </List>
              )}
            </>
          )}
        </>
      )}

      {/* Pending Users Tab */}
      {tabValue === 1 && (
        <List>
          {filteredPendingUsers.map(([id, user]) => (
            <React.Fragment key={id}>
              <ListItem>
                <ListItemText
                  primary={
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" component="div">
                        {user.name}
                      </Typography>
                      <Chip
                        label="Pending"
                        color="warning"
                        size="small"
                      />
                    </Box>
                  }
                  secondary={
                    <Box>
                      {renderUserInfo(user)}
                    </Box>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => handleApproveUser(id)}
                    sx={{ mr: 1 }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleDenyUser(id)}
                  >
                    Deny
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      )}

      {/* Existing Edit Dialog */}
      {/* ... */}

      {/* Confirmation Dialog */}
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
          setConfirmAction(null);
        }}
      >
        <ConfirmDialogTitle>
          {confirmAction?.type === 'delete' ? 'Delete User' :
           confirmAction?.type === 'reset' ? 'Reset Password' :
           confirmAction?.type === 'block' ? 'Block User' : 'Unblock User'}
        </ConfirmDialogTitle>
        <ConfirmDialogContent>
          <Typography>
            Are you sure you want to {confirmAction?.type} {confirmAction?.userName}?
            {confirmAction?.type === 'reset' && 
              " The password will be reset to '123456'."}
          </Typography>
        </ConfirmDialogContent>
        <ConfirmDialogActions>
          <Button 
            onClick={() => {
              setConfirmDialogOpen(false);
              setConfirmAction(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmAction}
            variant="contained"
            color={
              confirmAction?.type === 'delete' ? 'error' :
              confirmAction?.type === 'block' ? 'error' :
              'primary'
            }
            autoFocus
          >
            Confirm
          </Button>
        </ConfirmDialogActions>
      </ConfirmDialog>

      {/* Password Reset Dialog */}
      <Dialog
        open={!!passwordReset}
        onClose={() => setPasswordReset(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Reset Password for {passwordReset?.userName}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              autoFocus
              fullWidth
              label="New Password"
              type={passwordReset?.showPassword ? 'text' : 'password'}
              value={passwordReset?.newPassword || ''}
              onChange={(e) => setPasswordReset(prev => 
                prev ? { ...prev, newPassword: e.target.value } : null
              )}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setPasswordReset(prev => 
                      prev ? { ...prev, showPassword: !prev.showPassword } : null
                    )}
                    edge="end"
                  >
                    {passwordReset?.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <Typography variant="caption" color="textSecondary">
              Password must be at least 6 characters long
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setPasswordReset(null)}>
            Cancel
          </Button>
          <Button
            onClick={handlePasswordReset}
            variant="contained"
            color="primary"
            disabled={!passwordReset?.newPassword || passwordReset.newPassword.length < 6}
          >
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Users; 