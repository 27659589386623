import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import {
  PeopleAlt as PeopleIcon,
  ShoppingCart as OrderIcon,
  Inventory as ProductIcon,
  TrendingUp as RevenueIcon,
} from '@mui/icons-material';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { Product, Order, User } from '../types/types';
import SearchBar from './common/SearchBar';
import { FirebaseService } from '../services/firebase.service';
import { useNavigate } from 'react-router-dom';

interface DashboardProps {
  adminId: string;
}

const Dashboard: React.FC<DashboardProps> = ({ adminId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  
  const { data: products = {}, loading: productsLoading } = useFirebaseData<Product>(FirebaseService.getProducts);
  const { data: orders = {}, loading: ordersLoading } = useFirebaseData<Order>(FirebaseService.getOrders);
  const { data: users = {}, loading: usersLoading } = useFirebaseData<User>(FirebaseService.getUsers);

  // Show loading state if any data is still loading or no adminId
  if (productsLoading || ordersLoading || usersLoading || !adminId) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  // Filter data using sellerId
  const adminProducts = Object.entries(products).filter(([_, product]) => {
    const matches = product.sellerId === adminId;
    return matches;
  }).map(([_, product]) => product);

  const adminOrders = Object.entries(orders).filter(([_, order]) => {
    const matches = order.sellerId === adminId;
    return matches;
  }).map(([_, order]) => order);

  const adminUsers = Object.entries(users).filter(([_, user]) => {
    const matches = user.sellerId === adminId;
    return matches;
  }).map(([_, user]) => user);

  const stats = {
    totalProducts: adminProducts.length,
    totalOrders: adminOrders.length,
    totalUsers: adminUsers.length,
    totalRevenue: adminOrders.reduce((acc, order) => acc + (order.totalAmount || 0), 0)
  };

  // Add this function to handle card clicks
  const handleCardClick = (route: string) => {
    navigate(route);
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 3 } }}>
      <Box 
        display="flex" 
        flexDirection={{ xs: 'column', sm: 'row' }} 
        justifyContent="space-between" 
        alignItems={{ xs: 'stretch', sm: 'center' }} 
        gap={2}
        mb={3}
      >
        <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
          Dashboard
        </Typography>
        <SearchBar adminId={adminId} />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Card 
            sx={{ 
              bgcolor: 'primary.main', 
              color: 'white',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'primary.dark',
                transform: 'translateY(-2px)',
                transition: 'all 0.2s'
              }
            }}
            onClick={() => handleCardClick('/users')}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <PeopleIcon fontSize="large" />
                <Box>
                  <Typography variant="h6">Users</Typography>
                  <Typography variant="h4">{stats.totalUsers}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card 
            sx={{ 
              bgcolor: 'secondary.main', 
              color: 'white',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'secondary.dark',
                transform: 'translateY(-2px)',
                transition: 'all 0.2s'
              }
            }}
            onClick={() => handleCardClick('/orders')}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <OrderIcon fontSize="large" />
                <Box>
                  <Typography variant="h6">Orders</Typography>
                  <Typography variant="h4">{stats.totalOrders}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card 
            sx={{ 
              bgcolor: 'success.main', 
              color: 'white',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'success.dark',
                transform: 'translateY(-2px)',
                transition: 'all 0.2s'
              }
            }}
            onClick={() => handleCardClick('/products')}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <ProductIcon fontSize="large" />
                <Box>
                  <Typography variant="h6">Products</Typography>
                  <Typography variant="h4">{stats.totalProducts}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card 
            sx={{ 
              bgcolor: 'warning.main', 
              color: 'white',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'warning.dark',
                transform: 'translateY(-2px)',
                transition: 'all 0.2s'
              }
            }}
            onClick={() => handleCardClick('/analytics')}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <RevenueIcon fontSize="large" />
                <Box>
                  <Typography variant="h6">Revenue</Typography>
                  <Typography variant="h4">₹{stats.totalRevenue.toLocaleString()}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Add more dashboard widgets here */}
      </Grid>
    </Box>
  );
};

export default Dashboard; 