import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Stack,
  Chip,
  Typography,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Category } from '../types/types';
import { FirebaseService } from '../services/firebase.service';
import { ImageSizes } from '../utils/imageOptimizer';
import ImageSelector from './common/ImageSelector';

interface CategoryFormProps {
  open: boolean;
  onClose: () => void;
  category?: Category;
  adminId: string;
  onSuccess?: () => void;
}

const CategoryForm: React.FC<CategoryFormProps> = ({ open, onClose, category, adminId, onSuccess }) => {
  const [formData, setFormData] = useState<Category>(() => {
    if (category) {
      return {
        ...category,
        id: category.id,
        name: category.name,
        imageUrl: category.imageUrl,
        sellerId: category.sellerId || adminId,
        subcategories: category.subcategories || [],
        description: category.description || '',
        stability: category.stability || 1,
      };
    }
    // Default values for new category
    return {
      id: `CAT${Date.now()}`, // Generate a new ID for new categories
      name: '',
      imageUrl: '',
      sellerId: adminId,
      subcategories: [],
      description: '',
      stability: 1,
    };
  });

  // Reset form data when category changes
  useEffect(() => {
    if (category) {
      setFormData({
        ...category,
        id: category.id,
        name: category.name,
        imageUrl: category.imageUrl,
        sellerId: category.sellerId || adminId,
        subcategories: category.subcategories || [],
        description: category.description || '',
        stability: category.stability || 1,
      });
    } else {
      setFormData({
        id: `CAT${Date.now()}`,
        name: '',
        imageUrl: '',
        sellerId: adminId,
        subcategories: [],
        description: '',
        stability: 1,
      });
    }
  }, [category, adminId]);

  const [newSubcategory, setNewSubcategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState<string>('');

  const handleImageSelect = async (fileOrUrl: File | string) => {
    setIsLoading(true);
    try {
      let imageUrl: string;
      if (fileOrUrl instanceof File) {
        // Upload new file
        imageUrl = await FirebaseService.uploadCategoryImage(fileOrUrl);
      } else {
        // Use existing URL from storage
        imageUrl = fileOrUrl;
      }
      setFormData(prev => ({ ...prev, imageUrl }));
      setImageError('');
    } catch (error) {
      console.error('Error processing image:', error);
      setImageError('Failed to process image. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddSubcategory = () => {
    if (newSubcategory.trim()) {
      setFormData(prev => ({
        ...prev,
        subcategories: [
          ...(prev.subcategories || []),
          { 
            id: `SUB${Date.now()}`,
            name: newSubcategory.trim(),
            stability: 1
          }
        ]
      }));
      setNewSubcategory('');
    }
  };

  const handleRemoveSubcategory = (subcategoryId: string) => {
    setFormData(prev => ({
      ...prev,
      subcategories: prev.subcategories?.filter(sub => sub.id !== subcategoryId) || []
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!formData.name || !formData.imageUrl) return;

    setIsLoading(true);
    try {
      if (category) {
        await FirebaseService.updateCategory(category.id, {
          ...formData,
          id: category.id, // Ensure we're using the original ID for updates
        });
      } else {
        await FirebaseService.addCategory({
          ...formData,
          id: `CAT${Date.now()}`, // Generate new ID for new categories
        });
      }
      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error saving category:', error);
      setImageError('Failed to save category');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{category ? 'Edit Category' : 'Add New Category'}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Category Name"
            value={formData.name || ''}
            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            sx={{ mb: 2 }}
          />

          <Box sx={{ mb: 2 }}>
            <ImageSelector
              onImageSelect={handleImageSelect}
              folder="categories"
              maxSize={ImageSizes.CATEGORY}
            />
            {formData.imageUrl && (
              <Box mt={2}>
                <img
                  src={formData.imageUrl}
                  alt="Category"
                  style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain' }}
                />
              </Box>
            )}
            {imageError && (
              <Typography color="error" variant="caption" display="block" sx={{ mt: 1 }}>
                {imageError}
              </Typography>
            )}
          </Box>

          <Box sx={{ mb: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                label="Add Subcategory"
                value={newSubcategory}
                onChange={(e) => setNewSubcategory(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleAddSubcategory()}
              />
              <IconButton onClick={handleAddSubcategory} color="primary">
                <AddIcon />
              </IconButton>
            </Stack>
            <Box sx={{ mt: 2 }}>
              {formData.subcategories?.map((sub) => (
                <Chip
                  key={sub.id}
                  label={sub.name}
                  onDelete={() => handleRemoveSubcategory(sub.id)}
                  sx={{ m: 0.5 }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isLoading || !formData.name || !formData.imageUrl}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryForm; 