import React from 'react';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { FirebaseService } from '../services/firebase.service';
import { Order, OrderProduct, Product } from '../types/types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Grid,
  Paper,
  TextField,
  MenuItem,
  TableContainer,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OptimizedImage from './common/OptimizedImage';

interface OrderDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  order: Order & {
    userName: string;
    userAddress?: string;
    userPhone?: string;
  };
  onStatusChange: (orderId: string, status: string) => void;
}

const OrderDetailsDialog: React.FC<OrderDetailsDialogProps> = ({ open, onClose, order, onStatusChange }) => {
  // Fetch all products to get their shortIds
  const { data: productsData = {} } = useFirebaseData<Product>(FirebaseService.getProducts);

  // Function to get product shortId
  const getProductShortId = (productId: string) => {
    return productsData[productId]?.shortId || productId?.slice(-6);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">
          Order Details - {order.shortId}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {/* Customer Details */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Customer Information</Typography>
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                <Typography>Name: {order.userName}</Typography>
                <Typography>Phone: {order.userPhone || 'N/A'}</Typography>
                <Typography>Order Date: {new Date(order.date).toLocaleString()}</Typography>
                <Typography>Delivery Time: {new Date(order.deliveryTime).toLocaleString()}</Typography>
                <Typography>
                  Pickup Type: {order.storePickup ? 'Store Pickup' : 'Home Delivery'}
                </Typography>
                {!order.storePickup && (
                  <Typography>Address: {order.userAddress || 'N/A'}</Typography>
                )}
              </Box>
            </Paper>
          </Grid>

          {/* Order Status */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Order Status</Typography>
                <TextField
                  select
                  size="small"
                  value={order.status}
                  onChange={(e) => onStatusChange(order.id || '', e.target.value)}
                  sx={{ minWidth: 150 }}
                >
                  {['pending', 'processing', 'out for delivery', 'picked up', 'delivered', 'cancelled'].map(status => (
                    <MenuItem key={status} value={status}>
                      {status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Chip
                  label={order.status}
                  color={
                    order.status === 'delivered' ? 'success' :
                    order.status === 'cancelled' ? 'error' :
                    'primary'
                  }
                />
              </Box>
            </Paper>
          </Grid>

          {/* Products */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Products</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.products.map((product) => (
                      <TableRow key={product.productId}>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <OptimizedImage
                              src={product.productImage}
                              alt={product.productName}
                              width={50}
                              height={50}
                              style={{
                                borderRadius: '4px'
                              }}
                            />
                            <Typography>{product.productName}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{getProductShortId(product.productId)}</TableCell>
                        <TableCell align="right">
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            {product.productDiscountedPrice ? (
                              <>
                                <Typography
                                  component="span"
                                  sx={{
                                    textDecoration: 'line-through',
                                    color: 'text.secondary',
                                    fontSize: '0.875rem'
                                  }}
                                >
                                  ₹{product.productPrice}
                                </Typography>
                                <Typography component="span" color="error">
                                  ₹{product.productDiscountedPrice}
                                </Typography>
                              </>
                            ) : (
                              <Typography>₹{product.productPrice}</Typography>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="right">{product.quantity}</TableCell>
                        <TableCell align="right">
                          ₹{((product.productDiscountedPrice || product.productPrice) * product.quantity).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          {/* Order Summary */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Order Summary</Typography>
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 2 }}>
                <Typography>Subtotal:</Typography>
                <Typography align="right">₹{order.totalAmount - order.deliveryCharge}</Typography>
                <Typography>Delivery Charge:</Typography>
                <Typography align="right">₹{order.deliveryCharge}</Typography>
                <Typography variant="h6">Total Amount:</Typography>
                <Typography variant="h6" align="right">₹{order.totalAmount}</Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetailsDialog; 