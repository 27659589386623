import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Chip,
  Switch,
  Tooltip,
  Card,
  Divider,
  Grid,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFirebaseData } from '../hooks/useFirebaseData';
import { FirebaseService } from '../services/firebase.service';
import { Product, Category } from '../types/types';
import ProductForm from './ProductForm';
import BulkProductUpload from './BulkProductUpload';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PageHeader from './common/PageHeader';
import FilterBar from './common/FilterBar';
import OptimizedImage from './common/OptimizedImage';

interface ProductsProps {
  adminId: string;
  adminEmail: string;
}

const Products: React.FC<ProductsProps> = ({ adminId, adminEmail }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { data: products = {}, loading: productsLoading } = useFirebaseData<Product>(
    FirebaseService.getProducts,
    [refreshTrigger]
  );
  const { data: categories = {}, loading: categoriesLoading } = useFirebaseData<Category>(
    FirebaseService.getCategories
  );

  const [openForm, setOpenForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>({});

  const filterConfig = [
    {
      field: 'category',
      label: 'Category',
      type: 'select' as const,
      options: Object.values(categories).map(cat => ({
        label: cat.name,
        value: cat.id
      }))
    },
    {
      field: 'status',
      label: 'Status',
      type: 'select' as const,
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
      ]
    },
    {
      field: 'stock',
      label: 'Stock Status',
      type: 'select' as const,
      options: [
        { label: 'In Stock', value: 'in_stock' },
        { label: 'Low Stock', value: 'low_stock' },
        { label: 'Out of Stock', value: 'out_of_stock' }
      ]
    },
    {
      field: 'price',
      label: 'Price Range',
      type: 'select' as const,
      options: [
        { label: 'Under ₹100', value: 'under_100' },
        { label: '₹100 - ₹500', value: '100_500' },
        { label: '₹500 - ₹1000', value: '500_1000' },
        { label: 'Over ₹1000', value: 'over_1000' }
      ]
    }
  ];

  const adminProducts = Object.entries(products)
    .filter(([_, product]) => product.sellerId === adminId)
    .sort((a, b) => b[1].stability - a[1].stability);

  const filteredProducts = useMemo(() => {
    return adminProducts
      .filter(([_, product]) => {
        if (filters.category && product.categoryId !== filters.category) return false;
        
        if (filters.status) {
          const isBlocked = product.blocked;
          if (filters.status === 'active' && isBlocked) return false;
          if (filters.status === 'inactive' && !isBlocked) return false;
        }
        
        if (filters.stock) {
          switch (filters.stock) {
            case 'out_of_stock':
              if (product.stock !== 0) return false;
              break;
            case 'low_stock':
              if (product.stock <= 0 || product.stock > 10) return false;
              break;
            case 'in_stock':
              if (product.stock <= 10) return false;
              break;
          }
        }

        if (filters.price) {
          const price = product.price;
          switch (filters.price) {
            case 'under_100':
              if (price >= 100) return false;
              break;
            case '100_500':
              if (price < 100 || price > 500) return false;
              break;
            case '500_1000':
              if (price < 500 || price > 1000) return false;
              break;
            case 'over_1000':
              if (price <= 1000) return false;
              break;
          }
        }

        return true;
      })
      .map(([_, product]) => ({ ...product }));
  }, [adminProducts, filters]);

  const handleAdd = () => {
    setSelectedProduct(undefined);
    setOpenForm(true);
  };

  const handleEdit = (productId: string, product: Product) => {
    console.log('Editing product:', { productId, product });
    const productToEdit: Product = {
      ...product,
      id: productId,
      categoryId: product.categoryId || '',
      subcategoryId: product.subcategoryId || '',
    };
    setSelectedProduct(productToEdit);
    setOpenForm(true);
  };

  const handleDelete = async (productId: string) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await FirebaseService.deleteProduct(productId);
        setRefreshTrigger(prev => prev + 1);
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const handleRefresh = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  const handleToggleStatus = async (productId: string, currentStatus: boolean) => {
    try {
      await FirebaseService.updateProduct(productId, {
        blocked: !currentStatus
      });
      handleRefresh();
    } catch (error) {
      console.error('Error toggling product status:', error);
    }
  };

  const renderMobileView = (products: Product[]) => (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      {products.map((product) => (
        <Card key={product.id} sx={{ mb: 2, position: 'relative' }}>
          <Box sx={{ display: 'flex', p: 2 }}>
            <Box sx={{ mr: 2 }}>
              <OptimizedImage
                src={product.imageUrl}
                alt={product.name}
                width={80}
                height={80}
                style={{
                  borderRadius: '8px'
                }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                {product.name}
              </Typography>
              <Typography variant="caption" color="textSecondary" display="block">
                ID: {product.shortId}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                {product.discountPercent > 0 ? (
                  <Box>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ textDecoration: 'line-through' }}
                    >
                      ₹{product.price}
                    </Typography>
                    <Typography variant="h6" color="primary">
                      ₹{Math.floor(product.price * (1 - product.discountPercent/100))}
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="h6" color="primary">
                    ₹{product.price}
                  </Typography>
                )}
                {product.discountPercent > 0 && (
                  <Chip
                    label={`${product.discountPercent}% OFF`}
                    color="error"
                    size="small"
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">
                  Category
                </Typography>
                <Typography variant="body2">
                  {product.categoryName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">
                  Subcategory
                </Typography>
                <Typography variant="body2">
                  {product.subcategoryName}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">
                  Stock
                </Typography>
                <Typography 
                  variant="body2"
                  color={product.stock < 10 ? 'error' : 'inherit'}
                >
                  {product.stock}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">
                  Quantity
                </Typography>
                <Typography variant="body2">
                  {product.productQuantity}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption" color="textSecondary">
                  Unit
                </Typography>
                <Typography variant="body2">
                  {product.measurementUnit}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Switch
                checked={!product.blocked}
                onChange={() => handleToggleStatus(product.id, product.blocked)}
                size="small"
                color="success"
              />
              <Chip
                icon={product.blocked ? <BlockIcon /> : <CheckCircleIcon />}
                label={product.blocked ? 'Inactive' : 'Active'}
                color={product.blocked ? 'error' : 'success'}
                size="small"
                variant="outlined"
              />
            </Box>
            <Box>
              <IconButton 
                onClick={() => handleEdit(product.id, product)}
                color="primary"
                size="small"
              >
                <EditIcon />
              </IconButton>
              <IconButton 
                onClick={() => handleDelete(product.id)}
                color="error"
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  );

  const renderDesktopView = (products: Product[]) => (
    <TableContainer 
      component={Paper} 
      sx={{ 
        display: { 
          xs: 'none', 
          md: 'block' 
        } 
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Subcategory</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell align="right">Discount</TableCell>
            <TableCell align="right">Stock</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell>Unit</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell>
                <OptimizedImage
                  src={product.imageUrl}
                  alt={product.name}
                  width={50}
                  height={50}
                  style={{ 
                    borderRadius: '4px'
                  }}
                />
              </TableCell>
              <TableCell>
                <Typography variant="body2" noWrap>
                  {product.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  ID: {product.shortId}
                </Typography>
              </TableCell>
              <TableCell>{product.categoryName}</TableCell>
              <TableCell>{product.subcategoryName}</TableCell>
              <TableCell align="right">
                {product.discountPercent > 0 ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ textDecoration: 'line-through' }}
                    >
                      ₹{product.price}
                    </Typography>
                    <Typography variant="body1" color="primary" sx={{ fontWeight: 'medium' }}>
                      ₹{Math.floor(product.price * (1 - product.discountPercent/100))}
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="body2">₹{product.price}</Typography>
                )}
              </TableCell>
              <TableCell align="right">
                {product.discountPercent > 0 ? (
                  <Chip
                    label={`${product.discountPercent}% OFF`}
                    color="error"
                    size="small"
                    sx={{ height: '20px' }}
                  />
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell align="right">
                <Typography 
                  variant="body2" 
                  color={product.stock < 10 ? 'error' : 'inherit'}
                >
                  {product.stock}
                </Typography>
              </TableCell>
              <TableCell align="right">{product.productQuantity}</TableCell>
              <TableCell>{product.measurementUnit}</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Tooltip 
                    title={product.blocked ? 'Click to Activate' : 'Click to Deactivate'}
                    placement="top"
                  >
                    <Switch
                      checked={!product.blocked}
                      onChange={() => handleToggleStatus(product.id, product.blocked)}
                      size="small"
                      color="success"
                    />
                  </Tooltip>
                  <Chip
                    icon={product.blocked ? <BlockIcon /> : <CheckCircleIcon />}
                    label={product.blocked ? 'Inactive' : 'Active'}
                    color={product.blocked ? 'error' : 'success'}
                    size="small"
                    variant="outlined"
                    sx={{ 
                      minWidth: 90,
                      '& .MuiChip-icon': {
                        fontSize: '1rem'
                      }
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <IconButton 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(product.id, product);
                  }} 
                  color="primary"
                  size="small"
                >
                  <EditIcon />
                </IconButton>
                <IconButton 
                  onClick={() => handleDelete(product.id)} 
                  color="error"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {products.length === 0 && (
            <TableRow>
              <TableCell colSpan={11} align="center">
                <Typography variant="body1" sx={{ py: 2 }}>
                  No products found. Click the Add Product button to create one.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (productsLoading || categoriesLoading) {
    return <Box p={3}>Loading...</Box>;
  }

  return (
    <Box p={2}>
      <PageHeader 
        title={`Products (${filteredProducts.length})`}
        adminId={adminId}
        action={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAdd}
            fullWidth={isMobile}
          >
            Add Product
          </Button>
        }
      />

      <FilterBar
        filters={filters}
        config={filterConfig}
        onChange={setFilters}
      />

      {renderMobileView(filteredProducts)}
      {renderDesktopView(filteredProducts)}

      <ProductForm
        open={openForm}
        onClose={() => {
          setOpenForm(false);
          handleRefresh();
        }}
        product={selectedProduct}
        adminId={adminId}
        adminEmail={adminEmail}
        categories={categories}
      />

      <BulkProductUpload
        open={bulkUploadOpen}
        onClose={() => {
          setBulkUploadOpen(false);
          handleRefresh();
        }}
        adminId={adminId}
        adminEmail={adminEmail}
        categories={categories}
      />
    </Box>
  );
};

export default Products; 