import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Card,
  Typography,
  Chip,
  Button,
  TextField,
  MenuItem,
  Divider,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Order, User } from '../types/types';
import { FirebaseService, FirebaseServiceClass } from '../services/firebase.service';
import { useFirebaseData } from '../hooks/useFirebaseData';
import OrderDetailsDialog from './OrderDetailsDialog';
import PageHeader from './common/PageHeader';
import FilterBar from './common/FilterBar';
import { 
  ref,
  onValue,
  update,
} from 'firebase/database';
import { db as realtimeDb } from '../config/firebase';

interface OrdersProps {
  adminId: string;
}

interface OrderWithUser extends Order {
  userName: string;
  userAddress?: string;
  userPhone?: string;
}

const ORDER_STATUSES = ['pending', 'processing', 'out for delivery', 'picked up', 'delivered', 'cancelled'];

const Orders: React.FC<OrdersProps> = ({ adminId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedOrder, setSelectedOrder] = useState<OrderWithUser | null>(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [orders, setOrders] = useState<{ [key: string]: Order }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!adminId) return;

    const ordersRef = ref(realtimeDb, 'orders');
    const unsubscribe = onValue(ordersRef, (snapshot) => {
      const ordersData: { [key: string]: Order } = {};
      snapshot.forEach((childSnapshot) => {
        const order = childSnapshot.val() as Order;
        if (order.sellerId === adminId) {
          ordersData[childSnapshot.key!] = { 
            ...order, 
            id: childSnapshot.key 
          };
        }
      });
      setOrders(ordersData);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching orders:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [adminId]);

  const { data: usersData = {} } = useFirebaseData<User>(
    FirebaseService.getUsers
  );

  const adminOrders = useMemo(() => {
    return Object.entries(orders)
      .filter(([_, order]: [string, Order]) => 
        order && typeof order === 'object' && order.sellerId === adminId
      )
      .sort(([_, a]: [string, Order], [__, b]: [string, Order]) => 
        (b.date || 0) - (a.date || 0)
      );
  }, [orders, adminId]);

  const filterConfig = [
    {
      field: 'status',
      label: 'Status',
      type: 'select' as const,
      options: ORDER_STATUSES.map(status => ({
        label: status.charAt(0).toUpperCase() + status.slice(1),
        value: status
      }))
    },
    {
      field: 'pickupType',
      label: 'Delivery Type',
      type: 'select' as const,
      options: [
        { label: 'Home Delivery', value: 'delivery' },
        { label: 'Store Pickup', value: 'pickup' }
      ]
    },
    {
      field: 'userName',
      label: 'Customer Name',
      type: 'text' as const
    },
    {
      field: 'phoneNumber',
      label: 'Phone Number',
      type: 'text' as const
    },
    {
      field: 'timeRange',
      label: 'Time Range',
      type: 'select' as const,
      options: [
        { label: 'Today', value: 'today' },
        { label: 'Last 7 Days', value: 'week' },
        { label: 'Last 30 Days', value: 'month' },
        { label: 'This Month', value: 'this_month' },
        { label: 'This Year', value: 'this_year' }
      ]
    },
    {
      field: 'amount',
      label: 'Order Amount',
      type: 'select' as const,
      options: [
        { label: 'Under ₹500', value: 'under_500' },
        { label: '₹500 - ₹1000', value: '500_1000' },
        { label: '₹1000 - ₹5000', value: '1000_5000' },
        { label: 'Over ₹5000', value: 'over_5000' }
      ]
    }
  ];

  const filteredOrders = useMemo(() => {
    return adminOrders.filter(([_, order]) => {
      const userInfo = usersData[order.userId] || {};
      
      if (filters.status && order.status !== filters.status) return false;

      if (filters.pickupType) {
        const isStorePickup = order.storePickup;
        if (filters.pickupType === 'pickup' && !isStorePickup) return false;
        if (filters.pickupType === 'delivery' && isStorePickup) return false;
      }

      if (filters.userName) {
        const userName = userInfo.name?.toLowerCase() || '';
        if (!userName.includes(filters.userName.toLowerCase())) return false;
      }

      if (filters.phoneNumber) {
        const userPhone = userInfo.phoneNumber || '';
        if (!userPhone.includes(filters.phoneNumber)) return false;
      }

      if (filters.timeRange) {
        const orderDate = new Date(order.date);
        const now = new Date();
        
        switch (filters.timeRange) {
          case 'today':
            if (orderDate.toDateString() !== now.toDateString()) return false;
            break;
          case 'week':
            if ((now.getTime() - orderDate.getTime()) > 7 * 24 * 60 * 60 * 1000) return false;
            break;
          case 'month':
            if ((now.getTime() - orderDate.getTime()) > 30 * 24 * 60 * 60 * 1000) return false;
            break;
          case 'this_month':
            if (orderDate.getMonth() !== now.getMonth() || 
                orderDate.getFullYear() !== now.getFullYear()) return false;
            break;
          case 'this_year':
            if (orderDate.getFullYear() !== now.getFullYear()) return false;
            break;
        }
      }

      if (filters.amount) {
        const amount = order.totalAmount;
        switch (filters.amount) {
          case 'under_500':
            if (amount >= 500) return false;
            break;
          case '500_1000':
            if (amount < 500 || amount > 1000) return false;
            break;
          case '1000_5000':
            if (amount < 1000 || amount > 5000) return false;
            break;
          case 'over_5000':
            if (amount <= 5000) return false;
            break;
        }
      }

      return true;
    });
  }, [adminOrders, filters, usersData]);

  const handleStatusChange = async (orderId: string, newStatus: string) => {
    try {
      const orderRef = ref(realtimeDb, `orders/${orderId}`);
      await update(orderRef, {
        status: newStatus,
        lastUpdated: new Date().toISOString()
      });
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleViewDetails = (order: Order) => {
    const userInfo = usersData[order.userId] || {};
    const orderWithUser: OrderWithUser = {
      ...order,
      userName: userInfo.name || 'Unknown User',
      userAddress: userInfo.address,
      userPhone: userInfo.phoneNumber
    };
    setSelectedOrder(orderWithUser);
    setDetailsOpen(true);
  };

  return (
    <Box p={2}>
      <PageHeader 
        title={`Orders (${filteredOrders.length})`}
        adminId={adminId}
      />

      <FilterBar
        filters={filters}
        config={filterConfig}
        onChange={setFilters}
      />

      <Grid container spacing={2}>
        {filteredOrders.map(([id, order]) => (
          <Grid item xs={12} md={6} lg={4} key={id}>
            <Card>
              <Box sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6" component="div">
                    Order #{order.shortId || ''}
                  </Typography>
                  <Chip
                    label={order.status || ''}
                    color={
                      order.status === 'delivered' ? 'success' :
                      order.status === 'cancelled' ? 'error' :
                      'primary'
                    }
                    size="small"
                  />
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    Order Date
                  </Typography>
                  <Typography variant="body1">
                    {new Date(order.date || 0).toLocaleString()}
                  </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    Delivery Time
                  </Typography>
                  <Typography variant="body1">
                    {new Date(order.deliveryTime || 0).toLocaleString()}
                  </Typography>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    Total Amount
                  </Typography>
                  <Typography variant="body1" color="primary" sx={{ fontWeight: 'bold' }}>
                    ₹{order.totalAmount || 0}
                  </Typography>
                </Box>

                <Divider />

                <Box sx={{ p: 2, bgcolor: 'background.default' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextField
                      select
                      size="small"
                      value={order.status || ''}
                      onChange={(e) => handleStatusChange(id, e.target.value)}
                      sx={{ minWidth: 120 }}
                    >
                      {ORDER_STATUSES.map(status => (
                        <MenuItem key={status} value={status}>
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleViewDetails(order)}
                    >
                      View Details
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {selectedOrder && (
        <OrderDetailsDialog
          open={detailsOpen}
          onClose={() => {
            setDetailsOpen(false);
            setSelectedOrder(null);
          }}
          order={selectedOrder}
          onStatusChange={handleStatusChange}
        />
      )}
    </Box>
  );
};

export default Orders; 